import { ApexOptions } from "apexcharts";
import React from "react";
import Chart from "react-apexcharts";

import { Box } from "./Box";

type LineChartProps = {
  labels: string[];
  series: ApexAxisChartSeries;
};

export const LineChart = ({ labels, series }: LineChartProps) => {
  const options: ApexOptions = {
    chart: {
      type: "line",
      toolbar: {
        show: false,
      },
    },
    colors: ["#FB9F38", "#18C0F0"],
    stroke: {
      curve: "smooth",
    },
    markers: {
      size: 1,
    },
    xaxis: {
      categories: labels,
    },
    yaxis: {
      labels: {
        formatter: (value) => value.toFixed(0) + "%",
        style: {
          colors: ["#8B90A0"],
          fontSize: "14px",
          fontFamily: "Gotham Rounded",
          fontWeight: 350,
        },
      },
      min: 0,
      max: 100,
      tickAmount: 4,
    },
    legend: {
      fontFamily: "Gotham Rounded",
      fontSize: "14px",
      fontWeight: 350,
      position: "bottom",
      labels: {
        colors: "#615E83",
      },
      height: 50,
      markers: {
        offsetY: -1,
        offsetX: -1,
      },
      offsetY: 20,
      itemMargin: {
        horizontal: 8,
      },
    },
  };

  return (
    <Box css={{ padding: "10px 20px 20px 0" }}>
      <Chart options={options} series={series} type={"line"} />
    </Box>
  );
};
