import { Filter } from "@soovu/shared/types";
import React from "react";

import { useOutcomeData } from "../hooks/useOutcomeData";
import { not } from "../lib/util";

import { Box } from "./Box";
import { Loading } from "./Loading";
import { NoData } from "./NoData";

import { styled } from "@/stitches";

type AverageReductionProps = {
  filter?: Filter;
};

const Wrapper = styled(Box, {
  padding: "26px 34px 20px",
});

const Title = styled("h4", {
  fontWeight: 400,
  fontSize: 16,
  margin: "0 0 5px 0",
});

const Value = styled(Box, {
  background: "#F1F5F9",
  border: "1px solid #CBD5E1",
  borderRadius: 4,
  flex: 1,
  fontWeight: 400,
  fontSize: 14,
  padding: 4,
  textAlign: "center",
});

export const AverageReduction = ({ filter }: AverageReductionProps) => {
  const { data, loading } = useOutcomeData(filter);

  if (loading) {
    return <Loading />;
  }

  if (!data || isNaN(data.reduction.month3)) {
    return <NoData />;
  }

  const month3 = data.reduction.month3;
  const month6 = data.reduction.month6;

  if (not(month3) && not(month6)) {
    return <NoData />;
  }

  return (
    <Wrapper>
      <Title>Average Pain Reduction</Title>
      <Box css={{ display: "flex", gap: 16 }}>
        {month3 && <Value>3 Month: {Math.round(month3 * 100)}%</Value>}
        {month6 && <Value>6 Month: {Math.round(month6 * 100)}%</Value>}
      </Box>
    </Wrapper>
  );
};
