import { gql } from "@apollo/client";

export const PAIN_TYPE_COUNTS = gql`
  query GetPainTypeCounts($filter: Filter) {
    painTypeCounts(filter: $filter) {
      back
      hip
      joint
      legsFeet
      menstrual
      neck
      other
      shoulder
      total
    }
  }
`;
