import { gql, useQuery } from "@apollo/client";
import { createColumnHelper } from "@tanstack/react-table";
import React from "react";
import { Link } from "react-router-dom";

import { getCurrentMonth, getPartnerPath } from "../lib/util";
import { GQLResponse } from "../types/common.types";
import { Partner } from "../types/partner.types";

import { Box } from "./Box";
import { Button } from "./Button";
import { Loading } from "./Loading";
import { NoData } from "./NoData";
import Table from "./Table";

const GET_PARTNERS = gql`
  query GetAllPartners($pagination: Pagination, $filter: Filter) {
    partners(pagination: $pagination, filter: $filter) {
      pageInfo {
        currentPage
        totalPages
      }
      totalCount
      rows {
        name
        partnerUrl
        Enrollments {
          rowModifiedDatetime
        }
        Users {
          createdAt
        }
      }
    }
  }
`;

function getFirstDayMonth() {
  const date = new Date();
  return new Date(date.getFullYear(), date.getMonth(), 1).toISOString();
}

const columnHelper = createColumnHelper<Partner>();

const columns = [
  columnHelper.accessor("name", {
    cell: (info) => (
      <Link to={getPartnerPath(info.row.original.partnerUrl)}>
        <Box css={{ color: "#EF4F25", fontWeight: 350 }}>{info.getValue()}</Box>
      </Link>
    ),
    header: "Organization",
  }),
  columnHelper.accessor((row) => row.Users?.length.toFixed(0), {
    header: "Total",
  }),
  columnHelper.accessor(
    (row) =>
      row.Users?.filter(
        (u) => u && u.createdAt && u.createdAt >= getFirstDayMonth(),
      ).length.toFixed(0),
    {
      header: `Month (${getCurrentMonth()})`,
    },
  ),
  columnHelper.accessor("partnerUrl", {
    cell: (info) => (
      <Link to={getPartnerPath(info.getValue())}>
        <Button color="orange" size="large" outlined css={{ width: "100%" }}>
          View
        </Button>
      </Link>
    ),
    header: "Actions",
  }),
];

type CompaniesTableProps = {
  includeDirect?: boolean;
};

const CompaniesTable = ({ includeDirect = false }: CompaniesTableProps) => {
  const { data, loading } = useQuery<{ partners: GQLResponse<Partner> }>(
    GET_PARTNERS,
    {
      variables: {
        filter: {
          includeAssociations: true,
          includeDirect,
        },
      },
    },
  );

  if (loading) {
    return <Loading />;
  }

  if (!data) {
    return <NoData />;
  }

  return <Table columns={columns} data={data.partners} />;
};

export default CompaniesTable;
