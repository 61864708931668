import { ChevronRightIcon } from "@heroicons/react/24/outline";
import React from "react";
import { Link } from "react-router-dom";

import { Box } from "./Box";

import { styled } from "@/stitches";

type BreadCrumbProps = {
  parts: {
    label: string;
    path: string;
  }[];
};

const Container = styled(Box, {
  alignItems: "center",
  columnGap: 10,
  display: "flex",
  height: 24,
  padding: "16px 32px",
});

const PartStyled = styled(Box, {
  alignItems: "center",
  columnGap: 10,
  display: "flex",
});

const LinkStyled = styled(Link, {
  color: "#232735",
  "&:hover": {
    textDecoration: "underline",
  },
});

const IconStyled = styled(ChevronRightIcon, {
  color: "#CBD5E1",
  width: 20,
});

export const BreadCrumb = ({ parts }: BreadCrumbProps) => {
  return (
    <Container>
      {parts.map((part, i) => {
        const isLast = i === parts.length - 1;

        if (isLast) {
          return (
            <Box
              key={part.path}
              css={{
                color: "#FB9F38",
                fontWeight: 400,
              }}
            >
              {" "}
              {part.label}
            </Box>
          );
        }
        return (
          <PartStyled key={part.path}>
            <Box>
              <LinkStyled to={part.path ?? ""}>{part.label}</LinkStyled>{" "}
            </Box>
            <IconStyled />
          </PartStyled>
        );
      })}
    </Container>
  );
};
