import { useQuery } from "@apollo/client";
import {
  ALL_CONSUMER_ID,
  DIRECT_TO_CONSUMER_ID,
} from "@soovu/shared/constants";
import { Filter } from "@soovu/shared/types";
import { createColumnHelper, PaginationState } from "@tanstack/react-table";
import React, { useState } from "react";
import { Link } from "react-router-dom";

import {
  mapTreatmentDuration,
  mapTreatmentGain,
  mapTreatmentPrePostRating,
  mapTreatmentRating,
  mapTreatmentWaveform,
} from "../lib/map-labels";
import { ALL_GET_USAGE, GET_USAGE } from "../queries/usage.queries";
import { GQLResponse } from "../types/common.types";
import { Usage } from "../types/usage.types";

import { Box } from "./Box";
import Table from "./Table";

const columnHelper = createColumnHelper<Usage>();

const columnDictionary = {
  contact: columnHelper.accessor("id", {
    cell: (props) => {
      const row = props.row.original;
      const prefix =
        row.partnerId === DIRECT_TO_CONSUMER_ID ? "dtc" : "corporate";
      const id = (row?.id ?? 1000) - 1000;
      return (
        <>
          <Link to={`/${prefix}/user/${id}`}>
            {" "}
            <Box
              as={"span"}
              css={{ color: "#EF4F25", fontWeight: 350, whiteSpace: "nowrap" }}
            >
              {row.nameFirst} {row.nameLast}
            </Box>
          </Link>
          {/* <Box>{row.email}</Box>
          {row.altEmail && <Box>{row.altEmail}</Box>} */}
        </>
      );
    },
    header: "Contact",
  }),
  partner: columnHelper.accessor("partnerName", {
    cell: (cell) => cell.getValue(),
    header: "Partner",
  }),
  startTime: columnHelper.accessor("startTime", {
    cell: (cell) => {
      const value = new Date(cell.getValue());
      return (
        <>
          {value.toLocaleDateString()}
          <Box css={{ fontSize: 14, marginTop: 5 }}>
            {" "}
            at{" "}
            {value.toLocaleTimeString([], {
              hour: "numeric",
              minute: "2-digit",
              timeZoneName: "short",
            })}{" "}
          </Box>
        </>
      );
    },
    header: "Date / Time",
  }),
  program: columnHelper.accessor("Treatment.waveform", {
    cell: (cell) => mapTreatmentWaveform(cell.getValue()),
    header: "Program",
  }),
  duration: columnHelper.accessor("actualSeconds", {
    cell: (cell) => mapTreatmentDuration(cell.getValue()),
    header: "Duration",
  }),
  devices: columnHelper.accessor(
    (row) => {
      let count = 0;
      row.Treatment.serialNum.forEach((device) => device && ++count);
      return `${count}`;
    },
    {
      header: "# Devices",
    },
  ),
  temp: columnHelper.accessor("gain", {
    cell: (cell) => mapTreatmentGain(cell.getValue()),
    header: "Temp",
  }),
  location: columnHelper.accessor(
    (row) => `${row.BodyPartLookup.label ?? ""}`,
    {
      header: "Body Part",
    },
  ),
  preRating: columnHelper.accessor("Treatment.preRating", {
    cell: (cell) => mapTreatmentPrePostRating(cell.getValue()),
    header: "Pre Rating",
  }),
  rating: columnHelper.accessor(
    (row) => {
      if (row.Treatment.postRating !== null) {
        return mapTreatmentPrePostRating(row.Treatment.postRating);
      }
      return mapTreatmentRating(row.Treatment.rating);
    },
    {
      header: "Post Rating",
    },
  ),
};

const columns = [
  columnDictionary.contact,
  columnDictionary.partner,
  columnDictionary.startTime,
  columnDictionary.program,
  columnDictionary.duration,
  columnDictionary.devices,
  columnDictionary.temp,
  columnDictionary.location,
  columnDictionary.preRating,
  columnDictionary.rating,
];

interface TreatmentTableProps {
  filter?: Filter;
}

export const TreatmentTable = ({ filter }: TreatmentTableProps) => {
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const pagination = React.useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize],
  );

  const { data, loading } = useQuery<{ usage: GQLResponse<Usage> }>(
    filter?.partnerId === ALL_CONSUMER_ID ? ALL_GET_USAGE : GET_USAGE,
    {
      variables: {
        pagination: {
          page: pageIndex + 1,
          pageSize,
        },
        filter,
      },
    },
  );

  if (loading || !data) {
    return <Box>...loading</Box>;
  }

  if (filter?.partnerId !== ALL_CONSUMER_ID) {
    delete columns[1];
    delete columns[0];
  }
  return (
    <Table
      columns={columns}
      data={data.usage}
      onPaginationChange={setPagination}
      pagination={pagination}
    />
  );
};
