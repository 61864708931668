import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  from,
} from "@apollo/client";
import { BatchHttpLink } from "@apollo/client/link/batch-http";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { useAuth0 } from "@auth0/auth0-react";
import React, { ReactElement, ReactNode } from "react";

/**
 * ApolloProvider with the client
 */
export default function ApolloProviderWithClient({
  children,
}: {
  children: ReactNode;
}): ReactElement {
  const { getAccessTokenSilently, logout } = useAuth0();

  const client = React.useMemo(() => {
    /**
     * Init ApolloClient
     */
    const httpLink = new BatchHttpLink({
      uri: process.env.REACT_APP_SERVER_URI,
    });

    const errorLink = onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors)
        graphQLErrors.forEach(({ message, locations, path }) =>
          console.log(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
          ),
        );
      if (networkError) {
        console.log(`[Network error]: ${networkError}`);

        if (
          networkError.message.includes("code 401") ||
          networkError.message.includes("code 403")
        ) {
          // logout({ returnTo: window.location.origin });
          logout();
        }
      }
    });

    const authLink = setContext(async (_, { headers }) => {
      const token = await getAccessTokenSilently();

      return {
        headers: {
          ...headers,
          authorization: token ? `Bearer ${token}` : "",
        },
      };
    });

    return new ApolloClient({
      link: from([errorLink, authLink, httpLink]),
      cache: new InMemoryCache(),
    });
  }, [getAccessTokenSilently, logout]);

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}
