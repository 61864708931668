import * as RadixTabs from "@radix-ui/react-tabs";
import React from "react";

import { styled } from "@/stitches";

interface Tab {
  id?: string;
  label: string | React.ReactNode;
  content: string | React.ReactNode;
}

interface TabsProps {
  description: string;
  tabs: Tab[];
}

const RootStyled = styled(RadixTabs.Root, {
  marginTop: 16,

  "@bp3": {
    marginTop: 32,
  },
});

const ListStyled = styled(RadixTabs.List, {
  boxShadow:
    "0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06)",
  display: "flex",
});

const TriggerStyled = styled(RadixTabs.Trigger, {
  alignItems: "center",
  backgroundColor: "white",
  borderBottom: "2px solid transparent",
  borderRight: "1px solid #E5E7EB",
  borderTop: "2px solid transparent",
  boxSizing: "border-box",
  color: "#6B7280",
  cursor: "pointer",
  display: "flex",
  flex: "1 1 0",
  fontSize: 16,
  fontWeight: 350,
  justifyContent: "center",
  height: 56,
  "&:hover": {
    backgroundColor: "#F8FAFC",
  },
  "&[data-state='active']": {
    borderBottomColor: "#FB9F38",
    color: "#FB9F38",
  },
});

export const Tabs = ({ description, tabs }: TabsProps) => {
  const first_tab_id = !tabs[0].id ? "tab0" : tabs[0].id;
  const hash = !location.hash ? first_tab_id : location.hash.substring(1);

  const setHash = (hash: string | null) => {
    if (hash !== null) {
      location.hash = hash;
    }
  };

  return (
    <RootStyled defaultValue={hash}>
      <ListStyled aria-label={description}>
        {tabs.map((tab, i) => {
          const tab_key = !tab.id ? `tab${i}` : tab.id;
          return (
            <TriggerStyled
              key={tab_key}
              value={tab_key}
              onClick={() => setHash(!tab.id ? null : tab.id)}
            >
              {tab.label}
            </TriggerStyled>
          );
        })}
      </ListStyled>

      {tabs.map((tab, i) => {
        const tab_key = !tab.id ? `tab${i}` : tab.id;
        return (
          <RadixTabs.Content key={tab_key} value={tab_key}>
            {tab.content}
          </RadixTabs.Content>
        );
      })}
    </RootStyled>
  );
};
