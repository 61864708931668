import { useAuth0 } from "@auth0/auth0-react";
import {
  ArrowRightOnRectangleIcon,
  BuildingOfficeIcon,
  HomeIcon,
  UserGroupIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";
import * as NavigationMenu from "@radix-ui/react-navigation-menu";
import { SUPER_ADMIN } from "@soovu/shared/constants";
import React from "react";
import { useLocation } from "react-router-dom";

import { ACCESS } from "../types/role.types";

import { styled } from "@/stitches";

const StyledList = styled(NavigationMenu.List, {
  listStyle: "none",
  margin: "32px 0",
  padding: 0,
});

const StyledItem = styled(NavigationMenu.Item, {
  textAlign: "left",
});

const StyledLink = styled(NavigationMenu.Link, {
  alignItems: "center",
  color: "white",
  display: "flex",
  padding: "12px 36px",
  textDecoration: "none",

  "&[data-active]": {
    background: "white",
    color: "#EF4F25",
  },
});

const StyledIcon = styled("span", {
  height: 20,
  marginRight: 8,
  width: 20,
});

interface MenuItem {
  displayText: string;
  icon: React.ReactNode;
  path: string;
}

const Navigation = ({ access }: { access: ACCESS }) => {
  const { pathname } = useLocation();
  const { logout } = useAuth0();

  // eslint-disable-next-line no-unused-vars
  const [partner, admin, phi] = access;

  const drawItem = ({ displayText, icon, path }: MenuItem) => (
    <StyledItem key={path}>
      <StyledLink
        href={path}
        active={path !== "/" ? pathname.startsWith(path) : pathname === path}
      >
        <StyledIcon>{icon}</StyledIcon>
        {displayText}
      </StyledLink>
    </StyledItem>
  );

  return (
    <NavigationMenu.Root>
      {partner === SUPER_ADMIN && admin ? (
        <StyledList>
          {[
            {
              displayText: "Home",
              icon: <HomeIcon strokeWidth={2} />,
              path: "/",
            },
            ...(partner === SUPER_ADMIN
              ? [
                  {
                    displayText: "All User",
                    icon: <UserGroupIcon strokeWidth={2} />,
                    path: "/users",
                  },
                ]
              : []),
            {
              displayText: "Corporate Enrollment",
              icon: <BuildingOfficeIcon strokeWidth={2} />,
              path: "/corporate",
            },
            {
              displayText: "Direct to Consumer",
              icon: <UsersIcon strokeWidth={2} />,
              path: "/dtc",
            },
            // {
            //   displayText: "Internal Testers",
            //   icon: <ClipboardDocumentCheckIcon strokeWidth={2} />,
            //   path: "/testers",
            // },
            // {
            //   displayText: "Clinical Studies",
            //   icon: <BeakerIcon strokeWidth={2} />,
            //   path: "/clinical",
            // },
          ].map(drawItem)}
        </StyledList>
      ) : partner !== null ? (
        <StyledList>
          {[
            {
              displayText: "Home",
              icon: <HomeIcon strokeWidth={2} />,
              path: "/",
            },
          ].map(drawItem)}
        </StyledList>
      ) : (
        ""
      )}
      <StyledList>
        {/* {[
          {
            displayText: "Profile",
            icon: <UserCircleIcon strokeWidth={2} />,
            path: "/profile",
          },
        ].map(drawItem)} */}
        <StyledItem>
          <StyledLink
            href="#"
            // onClick={() => logout({ returnTo: window.location.origin })}
            onClick={() => logout()}
          >
            <StyledIcon>
              <ArrowRightOnRectangleIcon strokeWidth={2} />
            </StyledIcon>
            Log Out
          </StyledLink>
        </StyledItem>
      </StyledList>
    </NavigationMenu.Root>
  );
};

export default Navigation;
