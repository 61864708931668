import { gql } from "@apollo/client";

export const COUNT_TREATMENTS = gql`
  query CountTreatments($filter: Filter) {
    treatmentCounts(filter: $filter) {
      count
      thisMonth
    }
  }
`;
