import { gql } from "@apollo/client";

export const ENGAGEMENTS_BY_MONTH = gql`
  query GetEngagement($filter: Filter) {
    engagementByMonth(filter: $filter) {
      month
      engagement {
        partnerId
        count
        total
      }
    }
  }
`;
