import { styled } from "@/stitches";

export const Button = styled("button", {
  backgroundColor: "gainsboro",
  borderRadius: "9999px",
  border: "0",
  boxSizing: "border-box",
  fontWeight: 350,

  variants: {
    outlined: {
      true: {
        border: "1px solid",
      },
    },
    color: {
      orange: {
        backgroundColor: "#EF4F25",
        color: "white",
      },
    },
    size: {
      small: {
        fontSize: "13px",
        height: "25px",
        paddingRight: "10px",
        paddingLeft: "10px",
      },
      large: {
        fontSize: "15px",
        height: "35px",
        paddingLeft: "15px",
        paddingRight: "15px",
      },
    },
  },
  compoundVariants: [
    {
      color: "orange",
      outlined: true,
      css: {
        backgroundColor: "white",
        borderColor: "#EF4F25",
        color: "#EF4F25",
        "&:hover": {
          backgroundColor: "#EF4F25",
          color: "white",
        },
      },
    },
  ],
});
