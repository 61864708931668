import { useQuery } from "@apollo/client";
import { Filter } from "@soovu/shared/types";
import React from "react";

import { startCase } from "../lib/util";
import { PAIN_TYPE_COUNTS } from "../queries/pain-type.queries";
import { PainTypeCountsResponse } from "../types/pain-type.types";

import { BarChart } from "./BarChart";
import { Loading } from "./Loading";
import { NoData } from "./NoData";

export const PainTypeChart = ({ filter }: { filter?: Filter }) => {
  const labels: string[] = [];
  const series: number[] = [];

  const { data, loading } = useQuery<{
    painTypeCounts: PainTypeCountsResponse;
  }>(PAIN_TYPE_COUNTS, { variables: { filter } });

  if (loading) {
    return <Loading />;
  }

  if (!data) {
    return <NoData />;
  }

  Object.entries(data.painTypeCounts).forEach(([name, count]) => {
    if (name.startsWith("_") || name.startsWith("total") || !count) {
      return;
    }
    labels.push(startCase(name));
    series.push(count);
  });

  return (
    <BarChart
      series={[{ name: "Users", data: series }]}
      labels={labels}
      isColumn={true}
    />
  );
};
