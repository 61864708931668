import { gql } from "@apollo/client";

export const GET_OUTCOME_RESULTS = gql`
  query GetOutcomeResult($filter: Filter) {
    outcomeResult(filter: $filter) {
      partnerId
      pain {
        enrollment
        month3
        month6
        month9
        month12
      }
      satisfaction
      nps
      interfere {
        concentration
        enjoyment
        dayToDay
        recreational
        outsideOfHome
        socializing
        sleep
      }
      reduction {
        month3
        month6
        month9
        month12
      }
    }
  }
`;
