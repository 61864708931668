import { useQuery } from "@apollo/client";
import {
  ALL_CONSUMER_ID,
  DIRECT_TO_CONSUMER_ID,
} from "@soovu/shared/constants";
import { Filter } from "@soovu/shared/types";
import React from "react";

import { generatePercent, getCurrentMonth } from "../lib/util";
import { ENGAGEMENTS_BY_MONTH } from "../queries/engagement.queries";
import { EngagementResponse } from "../types/engagement.types";

import { BarChart } from "./BarChart";
import { Loading } from "./Loading";
import { NoData } from "./NoData";

type EngagementMonthlyCounts = {
  corp: number[];
  corpTotal: number[];
  dtc: number[];
  dtcTotal: number[];
};

type EngagementPercents = {
  corp: number[];
  dtc: number[];
};

type EngagementBarChartProps = {
  filter?: Filter;
};

export const EngagementBarChart = ({ filter }: EngagementBarChartProps) => {
  const { data, loading } = useQuery<{
    engagementByMonth: EngagementResponse[];
  }>(ENGAGEMENTS_BY_MONTH, {
    variables: {
      filter,
    },
  });

  if (loading) {
    return <Loading />;
  }

  if (!data) {
    return <NoData />;
  }

  const engagementCounts: EngagementPercents = {
    corp: [],
    dtc: [],
  };

  const labels: string[] = [];
  // const series: number[] = [];

  data.engagementByMonth.map((m) => {
    labels.push(getCurrentMonth(m.month));

    const output: EngagementMonthlyCounts = {
      corp: [],
      corpTotal: [],
      dtc: [],
      dtcTotal: [],
    };

    m.engagement.forEach((en) => {
      if (en.partnerId === "d2c") {
        output.dtc.push(en.count);
        output.dtcTotal.push(en.total);
      } else {
        output.corp.push(en.count);
        output.corpTotal.push(en.total);
      }
    });

    engagementCounts.dtc.push(generatePercent(output.dtc, output.dtcTotal));
    engagementCounts.corp.push(generatePercent(output.corp, output.corpTotal));
  });

  const series: { name: string; data: number[] }[] = [];

  if (filter?.partnerId && filter?.partnerId === DIRECT_TO_CONSUMER_ID) {
    series.push({
      name: "DTC",
      data: engagementCounts.dtc,
    });
  } else {
    if (filter?.includeDirect !== false) {
      series.push({
        name: "DTC",
        data: engagementCounts.dtc,
      });
    }

    series.push({
      name: filter?.partnerId === ALL_CONSUMER_ID ? "Engagements" : "Corporate",
      data: engagementCounts.corp,
    });
  }

  return (
    <BarChart
      series={series}
      labels={labels}
      isColumn={true}
      isPercent={true}
    />
  );
};
