import { XMarkIcon } from "@heroicons/react/24/outline";
import React, { useContext } from "react";

import { Context } from "../state/Context";
import { ACCESS } from "../types/role.types";

import Logo from "./Logo";
import Navigation from "./Navigation";

import { styled } from "@/stitches";

const Wrapper = styled("div", {
  background:
    process.env.REACT_APP_ENVIRONMENT === "uat"
      ? "lightcoral"
      : "linear-gradient(270deg, #EF4F25 0%, #F7B239 100%)",
  flex: "0 0 auto",
  padding: "32px 0 0 0",
  position: "fixed",
  top: 0,
  bottom: 0,
  width: "280px",
  zIndex: 3,

  "@bp3": {
    position: "static",
  },

  variants: {
    open: {
      false: {
        display: "none",

        "@bp3": {
          display: "block",
        },
      },
    },
  },
});

const StyledIcon = styled("span", {
  color: "#ffffff",
  display: "flex",
  height: 20,
  position: "absolute",
  right: 0,
  width: 20,
});

const Sidebar = ({ isOpen, access }: { isOpen: boolean; access: ACCESS }) => {
  const { menuOpen, setMenuOpen } = useContext(Context);
  return (
    <Wrapper open={isOpen}>
      <StyledIcon
        onClick={() => setMenuOpen(!menuOpen)}
        css={{ cursor: "pointer", margin: "0 20px" }}
      >
        <XMarkIcon strokeWidth={2} />
      </StyledIcon>
      <Logo />
      <Navigation access={access} />
    </Wrapper>
  );
};

export default Sidebar;
