import React from "react";

import { Box } from "./Box";
import { Loading } from "./Loading";
import { NoData } from "./NoData";
import { Panel } from "./Panel";

import { styled } from "@/stitches";

const Count = styled("div", {
  color: "#212B36",
  fontSize: "30px",
  fontWeight: 400,
  lineHeight: "36px",
});

const Label = styled("h4", {
  color: "#637381",
  fontSize: "12px",
  fontWeight: 350,
  margin: 0,
});

const Container = styled("div", {
  display: "flex",
  justifyContent: "space-between",
});

type UserCountProps = {
  count?: number;
  label: string;
  loading?: boolean;
  monthly?: number;
};

const Content = ({ count, label, loading, monthly }: UserCountProps) => {
  if (loading) {
    return <Loading />;
  }

  if (count === undefined) {
    return <NoData />;
  }

  return (
    <Container>
      <Box>
        <Count>{count.toLocaleString()}</Count>
        <Label>{label}</Label>
      </Box>
      {monthly !== undefined && (
        <Box
          css={{
            textAlign: "right",
          }}
        >
          <Count
            css={{
              color: "#EF4F25",
            }}
          >
            {monthly.toLocaleString()}
          </Count>
          <Label
            css={{
              color: "#EF4F25",
            }}
          >
            This Mo.
          </Label>
        </Box>
      )}
    </Container>
  );
};

export const UserCount = (props: UserCountProps) => {
  return (
    <Panel>
      <Box css={{ padding: "22px 32px" }}>
        <Content {...props} />
      </Box>
    </Panel>
  );
};
