import { gql } from "@apollo/client";

export const ENROLLMENTS_BY_MONTH = gql`
  query GetEnrollmentCounts($filter: Filter) {
    enrollmentCounts(filter: $filter) {
      month
      count
    }
  }
`;
