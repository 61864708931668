import { Filter } from "@soovu/shared/types";
import React from "react";

import { useOutcomeData } from "../hooks/useOutcomeData";
import { not } from "../lib/util";

import { Box } from "./Box";
import { Loading } from "./Loading";
import { NoData } from "./NoData";

import { styled } from "@/stitches";

type AveragePainProps = {
  filter?: Filter;
};

const Wrapper = styled(Box, {
  alignItems: "center",
  display: "flex",
  padding: "28px 34px",
});

const Title = styled("h4", {
  fontWeight: 400,
  fontSize: 16,
  margin: 0,
});

const Subtitle = styled("p", {
  fontSize: 14,
  margin: "5px 0 0 0",
});

const Value = styled(Box, {
  fontWeight: 400,
  fontSize: 24,
  flex: "0 0 auto",
});

export const AveragePain = ({ filter }: AveragePainProps) => {
  const { data, loading } = useOutcomeData(filter);

  if (loading) {
    return <Loading />;
  }

  if (!data || not(data.pain.enrollment)) {
    return <NoData />;
  }

  return (
    <Wrapper>
      <Box css={{ flex: 1 }}>
        <Title>Average Pain Score</Title>
        <Subtitle>At Enrollment</Subtitle>
      </Box>
      <Value>{data.pain.enrollment}</Value>
    </Wrapper>
  );
};
