import { gql } from "@apollo/client";

export const AGE_COUNTS = gql`
  query GetAges($filter: Filter) {
    ageCounts(filter: $filter) {
      age18To24
      age25To39
      age40To54
      age55Plus
      total
    }
  }
`;
