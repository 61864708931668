import { useQuery } from "@apollo/client";
import { Filter } from "@soovu/shared/types";
import React from "react";

import { GENDER_COUNTS } from "../queries/gender.queries";
import { GenderCountsResponse } from "../types/gender.types";

import { Loading } from "./Loading";
import { NoData } from "./NoData";
import { PieChart } from "./PieChart";

type AgesChartProps = {
  filter?: Filter;
};

export const GenderChart = ({ filter }: AgesChartProps) => {
  const labels = [];
  const series = [];

  const { data, loading } = useQuery<{
    genderCounts: GenderCountsResponse;
  }>(GENDER_COUNTS, { variables: { filter } });

  if (loading) {
    return <Loading />;
  }

  if (!data || !data.genderCounts) {
    return <NoData />;
  }

  const { anon, female, male, total } = data.genderCounts;

  if (male) {
    labels.push("Male");
    series.push(Math.round((male / total) * 100));
  }

  if (female) {
    labels.push("Female");
    series.push(Math.round((female / total) * 100));
  }

  if (anon) {
    labels.push("Anon");
    series.push(Math.round((anon / total) * 100));
  }

  return <PieChart series={series} labels={labels} />;
};
