import { useQuery } from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";
import {
  ArrowDownOnSquareIcon,
  ArrowDownOnSquareStackIcon,
} from "@heroicons/react/24/outline";
import { SUPER_ADMIN } from "@soovu/shared/constants";
import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

import { ActiveUsageTable } from "../components/ActiveUsageTable";
import { AgesChart } from "../components/AgesChart";
import { Box } from "../components/Box";
import { BreadCrumb } from "../components/BreadCrumb";
import { DurationChart } from "../components/DurationChart";
import { EngagementBarChart } from "../components/EngagementBarChart";
import { EnrollmentChart } from "../components/EnrollmentChart";
import { GainChart } from "../components/GainChart";
import { GenderChart } from "../components/GenderChart";
import { Grid } from "../components/Grid";
import { ImprovementChart } from "../components/ImprovementChart";
import { LocationChart } from "../components/LocationChart";
import { PainTypeChart } from "../components/PainTypeChart";
import { Panel } from "../components/Panel";
import { RatingChart } from "../components/RatingChart";
import { Search } from "../components/Search";
import { Tabs } from "../components/Tabs";
import { UserCount } from "../components/UserCount";
import { UserTable } from "../components/UserTable";
import { WaveformChart } from "../components/WaveformChart";
import { useTitle } from "../hooks/useTitle";
import { download_usage_report } from "../lib/download-usage-report";
import { GET_PARTNER_DETAILS } from "../queries/partner.queries";
import { COUNT_TREATMENTS } from "../queries/treatment.queries";
import { COUNT_USERS } from "../queries/user.queries";
import { OutcomeTemplate } from "../templates/outcome.template";
import { Partner } from "../types/partner.types";
import { ACCESS } from "../types/role.types";
import { TreatmentCountsResponse } from "../types/treatment.types";
import { UserCountsResponse } from "../types/user.types";

import { styled } from "@/stitches";

const Divider = styled("hr", {
  border: "1px solid #E2E8F0",
  display: "none",
  margin: "32px 0",
});

export const CompanyDetails = ({ access }: { access: ACCESS }) => {
  const { getAccessTokenSilently } = useAuth0();
  let { id } = useParams();
  const [title, setTitle] = useState<string | undefined>();

  // eslint-disable-next-line no-unused-vars
  const [partner, admin, phi] = access;
  if (!!partner && partner !== SUPER_ADMIN) {
    id = partner;
  }
  useTitle(title);

  const { data } = useQuery<{
    partnerDetails: Partner;
  }>(GET_PARTNER_DETAILS, {
    variables: {
      id,
    },
  });

  const { data: userData, loading: userLoading } = useQuery<{
    userCounts: UserCountsResponse[];
  }>(COUNT_USERS, {
    variables: {
      filter: {
        partnerId: id,
      },
    },
  });

  const { data: treatmentData, loading: treatmentLoading } = useQuery<{
    treatmentCounts: TreatmentCountsResponse;
  }>(COUNT_TREATMENTS, {
    variables: {
      filter: {
        partnerId: id,
      },
    },
  });

  const partnerDetails = data?.partnerDetails;
  const userCount = userData?.userCounts[0];
  const treatmentCount = treatmentData?.treatmentCounts;

  const breadCrumbParts = useMemo(() => {
    const parts = [];

    if (partnerDetails) {
      parts.push(
        {
          label: "Corporate Enrollment",
          path: "/corporate",
        },
        {
          label: partnerDetails.name,
          path: `/corporate/${partnerDetails.partnerUrl}`,
        },
      );
    }

    return parts;
  }, [partnerDetails]);

  useEffect(() => {
    setTitle(partnerDetails?.name);
  }, [partnerDetails]);

  return (
    <>
      {phi && <Search />}
      {!!partner && partner === SUPER_ADMIN && (
        <Grid>
          <Panel>
            <BreadCrumb parts={breadCrumbParts} />
          </Panel>
        </Grid>
      )}
      <Box as="h1">{data?.partnerDetails.name}</Box>
      <Grid>
        <UserCount
          count={userCount?.count}
          label="Corp. Enroll"
          loading={userLoading}
          monthly={userCount?.thisMonth}
        />
        <UserCount
          count={treatmentCount?.count}
          label="Total Treatments"
          loading={treatmentLoading}
          monthly={treatmentCount?.thisMonth}
        />
      </Grid>
      <Tabs
        description="Different types of reports"
        tabs={[
          {
            id: "enrollment",
            label: "Enrollment",
            content: (
              <>
                <Grid>
                  <Panel title="Monthly Enrollments">
                    <EnrollmentChart filter={{ partnerId: id }} />
                  </Panel>
                  <Panel title="Pain Types">
                    <PainTypeChart filter={{ partnerId: id }} />
                  </Panel>
                </Grid>
                <Grid>
                  <Panel title="Gender">
                    <GenderChart filter={{ partnerId: id }} />
                  </Panel>
                  <Panel title="Age">
                    <AgesChart filter={{ partnerId: id }} />
                  </Panel>
                </Grid>
                <Divider />
                {phi && (
                  <Grid>
                    <Panel title="Registrations">
                      <UserTable filter={{ partnerId: id }} />
                    </Panel>
                  </Grid>
                )}
              </>
            ),
          },
          {
            id: "engagement",
            label: "Engagement",
            content: (
              <>
                <Grid>
                  <Panel
                    title="Engaged by Month"
                    subTitle="% users that have had one or more HeatWave sessions per month"
                  >
                    <EngagementBarChart
                      filter={{ partnerId: id, includeDirect: false }}
                    />
                  </Panel>
                  <Panel
                    title="Heat Sessions"
                    subTitle="By pain location since program initiation"
                  >
                    <LocationChart
                      filter={{ partnerId: id, includeDirect: false }}
                    />
                  </Panel>
                  <Panel title="Improvement">
                    <ImprovementChart
                      filter={{ partnerId: id, includeDirect: false }}
                    ></ImprovementChart>
                  </Panel>
                </Grid>
                <Grid>
                  <Panel title="Heat Profile">
                    <WaveformChart
                      filter={{ partnerId: id, includeDirect: false }}
                    />
                  </Panel>
                  <Panel title="Duration">
                    <DurationChart
                      filter={{ partnerId: id, includeDirect: false }}
                    />
                  </Panel>
                  <Panel title="Temperature">
                    <GainChart
                      filter={{ partnerId: id, includeDirect: false }}
                    />
                  </Panel>
                </Grid>
                <Divider />
                <Grid>
                  {phi && (
                    <Panel
                      title="Usage Last 30 Days"
                      buttons={
                        <>
                          <a
                            href="#"
                            onClick={(
                              event: React.MouseEvent<HTMLAnchorElement>,
                            ) =>
                              download_usage_report(
                                event,
                                getAccessTokenSilently,
                                "30day",
                                id,
                              )
                            }
                          >
                            <ArrowDownOnSquareIcon strokeWidth={2} />
                          </a>
                          <a
                            href="#"
                            onClick={(
                              event: React.MouseEvent<HTMLAnchorElement>,
                            ) =>
                              download_usage_report(
                                event,
                                getAccessTokenSilently,
                                "full",
                                id,
                              )
                            }
                          >
                            <ArrowDownOnSquareStackIcon strokeWidth={2} />
                          </a>
                        </>
                      }
                    >
                      <ActiveUsageTable filter={{ partnerId: id }} />
                    </Panel>
                  )}
                  {!phi && (
                    <>
                      <Panel title="Pain Rating Change">
                        <RatingChart filter={{ partnerId: id }} />
                      </Panel>
                      <Panel></Panel>
                    </>
                  )}
                </Grid>
              </>
            ),
          },
          {
            id: "outcomes",
            label: "Outcomes",
            content: <OutcomeTemplate filter={{ partnerId: id }} />,
          },
        ]}
      />
    </>
  );
};
