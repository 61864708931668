import { Filter } from "@soovu/shared/types";
import React from "react";

import { useUsageData } from "../hooks/useUsageData";

import { Loading } from "./Loading";
import { NoData } from "./NoData";
import { PieChart } from "./PieChart";

type GainChartProps = {
  filter?: Filter;
};

export const GainChart = ({ filter }: GainChartProps) => {
  const { labels, loading, series } = useUsageData("gain", filter);

  if (loading) {
    return <Loading />;
  }

  if (!series) {
    return <NoData />;
  }

  return <PieChart series={series} labels={labels} />;
};
