import { startCase } from "./util";

const DICTIONARY = new Map([
  ["gain25", "Low"],
  ["gain50", "Med"],
  ["gain75", "High"],
  ["gain100", "Max"],
  ["min20", "20 Min"],
  ["min30", "30 Min"],
  ["min10", "10 Min"],
  ["min20", "20 Min"],
  ["min30", "30 Min"],
  ["rating-2", "Unspecified"],
  ["rating-1", "Skipped"],
  ["rating0", "No Relief"],
  ["rating1", "Little Relief"],
  ["rating2", "Better"],
  ["rating3", "High Relief"],
  ["rating4", "Max Relief"],
  ["prePostRating-2", "Unspecified"],
  ["prePostRating-1", "Skipped"],
  ["prePostRating0", "0 - No Pain"],
  ["prePostRating1", "1 - Very Mild"],
  ["prePostRating2", "2 - Discomforting"],
  ["prePostRating3", "3 - Tolerable"],
  ["prePostRating4", "4 - Moderate"],
  ["prePostRating5", "5 - Distressing"],
  ["prePostRating6", "6 - Very Distressing"],
  ["prePostRating7", "7 - Intense"],
  ["prePostRating8", "8 - Severe"],
  ["prePostRating9", "9 - Very Severe"],
  ["prePostRating10", "10 - Worst Possible"],
  ["waveform1", "Deep"],
  ["waveform2", "Soothe"],
  ["waveform3", "Breathe"],
]);

export function mapTreatmentDuration(value: number) {
  const minutes = Math.round(value / 60);
  return `${minutes} Min${minutes > 1 ? "s" : ""}`;

  // // MOST ROBUST SOLUTION BUT REQUIRES A LIBRARY (eg MOMENT.JS)
  // const duration = moment.duration(value, "seconds");
  //
  // const h = duration.hours();
  // const m = duration.minutes();
  //
  // const timeString: string[] = [];
  //
  // if (h) {
  //   timeString.push(`${h} Hour${h === 1 || "s"}`);
  // }
  //
  // if (m) {
  //   timeString.push(`${m} Min${m === 1 || "s"}`);
  // }
  //
  // return timeString.join(", ");
}

export function mapTreatmentGain(value: number) {
  return DICTIONARY.get("gain" + value) ?? "";
}

export function mapLabel(label: string) {
  return DICTIONARY.get(label) ?? startCase(label);
}

export function mapTreatmentPrePostRating(value: number) {
  return DICTIONARY.get("prePostRating" + value) ?? "";
}

export function mapTreatmentRating(value: number) {
  return DICTIONARY.get("rating" + value) ?? "";
}

export function mapTreatmentWaveform(value: number) {
  return DICTIONARY.get("waveform" + value) ?? "";
}
