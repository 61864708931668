import { styled } from "@/stitches";

export const Overlay = styled("div", {
  alignItems: "center",
  background: "inherit",
  display: "flex",
  inset: 0,
  justifyContent: "center",
  margin: 16,
  position: "absolute",
});
