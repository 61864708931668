import { useQuery } from "@apollo/client";
import React from "react";

import { generatePercent, getCurrentMonth } from "../lib/util";
import { ENGAGEMENTS_BY_MONTH } from "../queries/engagement.queries";
import { EngagementResponse } from "../types/engagement.types";

import { LineChart } from "./LineChart";
import { Loading } from "./Loading";
import { NoData } from "./NoData";

type EngagementMonthlyCounts = {
  corp: number[];
  corpTotal: number[];
  dtc: number[];
  dtcTotal: number[];
};

type EngagementPercents = {
  corp: number[];
  dtc: number[];
};

export const EngagementLineChart = () => {
  const { data, loading } = useQuery<{
    engagementByMonth: EngagementResponse[];
  }>(ENGAGEMENTS_BY_MONTH);

  if (loading) {
    return <Loading />;
  }

  if (!data) {
    return <NoData />;
  }

  const engagementCounts: EngagementPercents = {
    corp: [],
    dtc: [],
  };

  const labels: string[] = [];

  data.engagementByMonth.map((m) => {
    labels.push(getCurrentMonth(m.month));

    const output: EngagementMonthlyCounts = {
      corp: [],
      corpTotal: [],
      dtc: [],
      dtcTotal: [],
    };

    m.engagement.forEach((en) => {
      if (en.partnerId === "d2c") {
        output.dtc.push(en.count);
        output.dtcTotal.push(en.total);
      } else {
        output.corp.push(en.count);
        output.corpTotal.push(en.total);
      }
    });

    engagementCounts.dtc.push(generatePercent(output.dtc, output.dtcTotal));
    engagementCounts.corp.push(generatePercent(output.corp, output.corpTotal));
  });

  const series = [
    {
      name: "Corporate",
      data: engagementCounts.corp,
    },
    {
      name: "DTC",
      data: engagementCounts.dtc,
    },
  ];

  return <LineChart labels={labels} series={series} />;
};
