import { Filter } from "@soovu/shared/types";
import React from "react";

import { useOutcomeData } from "../hooks/useOutcomeData";
import { not } from "../lib/util";

import { BarChart } from "./BarChart";
import { Loading } from "./Loading";
import { NoData } from "./NoData";

export const PainScoreChart = ({ filter }: { filter?: Filter }) => {
  const labels: string[] = [];
  const series: number[] = [];

  const { data, loading } = useOutcomeData(filter);

  if (loading) {
    return <Loading />;
  }

  if (!data) {
    return <NoData />;
  }

  const { enrollment, month3, month6, month9, month12 } = data.pain;

  if (!not(enrollment)) {
    labels.push("Enrollment");
    series.push(enrollment);
  }

  if (!not(month3)) {
    labels.push("3 Mo");
    series.push(month3);
  }

  if (!not(month6)) {
    labels.push("6 Mo");
    series.push(month6);
  }

  if (!not(month9)) {
    labels.push("9 Mo");
    series.push(month9);
  }

  if (!not(month12)) {
    labels.push("12 Mo");
    series.push(month12);
  }

  return (
    <BarChart
      series={[{ name: "Avg. Pain Score", data: series }]}
      labels={labels}
      isColumn={true}
    />
  );
};
