import { useQuery } from "@apollo/client";
import { Filter } from "@soovu/shared/types";

import { mapLabel } from "../lib/map-labels";
import { percent, sumPoints } from "../lib/util";
import { GET_USAGE_COUNTS } from "../queries/usage.queries";
import { UsageCountResponse } from "../types/usage.types";

type HookOptions = {
  counts?: boolean;
  limit?: number | null;
  sort?: boolean;
};

type HookOutput = {
  labels: string[];
  loading: boolean;
  series: number[] | null;
};

export function useUsageData(
  field: keyof UsageCountResponse,
  filter: Filter | undefined,
  options: HookOptions = {
    counts: false,
    limit: null,
    sort: false,
  },
) {
  const { data, loading } = useQuery<{ usageCounts: UsageCountResponse[] }>(
    GET_USAGE_COUNTS,
    {
      variables: {
        filter,
      },
    },
  );

  const hookOutput: HookOutput = {
    labels: [],
    loading,
    series: null,
  };

  if (loading || !data) {
    return hookOutput;
  }

  const memo: { [key: string]: number } = {};
  let total = 0;

  data.usageCounts.forEach((usage) => {
    const fieldData = usage[field];

    if (!fieldData) {
      return;
    }

    Object.entries(fieldData).forEach(([name, count]) => {
      if (name.startsWith("_")) {
        return;
      }
      memo[name] = (memo[name] ?? 0) + count;
      total += count;
    });
  });

  const entries = Object.entries(memo);

  if (options.sort) {
    entries.sort((a, b) => {
      return b[1] - a[1];
    });

    if (options.limit && entries.length > options.limit) {
      const remaining = entries.splice(options.limit - 1);
      entries.push(["other", sumPoints(remaining)]);
    }
  }

  const labels: string[] = [];
  const series: number[] = [];

  entries.forEach(([name, count]) => {
    if (!count) {
      return;
    }
    labels.push(mapLabel(name));
    series.push(options.counts ? count : percent(count, total));
  });

  hookOutput.labels = labels;
  hookOutput.series = series;

  return hookOutput;
}
