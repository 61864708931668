import { useQuery } from "@apollo/client";
import { ALL_CONSUMER_ID } from "@soovu/shared/constants";
import React from "react";

import { Box } from "../components/Box";
import { EngagementBarChart } from "../components/EngagementBarChart";
import { Grid } from "../components/Grid";
import { LocationChart } from "../components/LocationChart";
import { Panel } from "../components/Panel";
import { Search } from "../components/Search";
import { TreatmentTable } from "../components/TreatmentTable";
import { UserCount } from "../components/UserCount";
import { useTitle } from "../hooks/useTitle";
import { COUNT_TREATMENTS } from "../queries/treatment.queries";
import { COUNT_USERS } from "../queries/user.queries";
import { ACCESS } from "../types/role.types";
import { TreatmentCountsResponse } from "../types/treatment.types";
import { UserCountsResponse } from "../types/user.types";

import { styled } from "@/stitches";

const Divider = styled("hr", {
  border: "1px solid #E2E8F0",
  display: "none",
  margin: "32px 0",
});

export const AllUsers = ({ access }: { access: ACCESS }) => {
  // eslint-disable-next-line no-unused-vars
  const [partner, admin, phi] = access;

  useTitle("All Customer Report");

  const { data: userData, loading: userLoading } = useQuery<{
    userCounts: UserCountsResponse[];
  }>(COUNT_USERS, {
    variables: {
      filter: {
        partnerId: ALL_CONSUMER_ID,
      },
    },
  });

  const { data: treatmentData, loading: treatmentLoading } = useQuery<{
    treatmentCounts: TreatmentCountsResponse;
  }>(COUNT_TREATMENTS, {
    variables: {
      filter: {
        partnerId: ALL_CONSUMER_ID,
      },
    },
  });

  const userCount = userData?.userCounts[0];
  const treatmentCount = treatmentData?.treatmentCounts;

  return (
    <>
      <Search />
      <Box as="h1">All Customer Report</Box>
      <Grid>
        <UserCount
          count={userCount?.count}
          label="Total Enrolled"
          loading={userLoading}
          monthly={userCount?.thisMonth}
        />
        <UserCount
          count={treatmentCount?.count}
          label="Total Treatments"
          loading={treatmentLoading}
          monthly={treatmentCount?.thisMonth}
        />
      </Grid>

      <Divider />

      <Grid>
        <Panel
          title="Engaged by Month"
          subTitle="% users that have had one or more HeatWave sessions per month"
        >
          <EngagementBarChart
            filter={{ partnerId: ALL_CONSUMER_ID, includeDirect: false }}
          />
        </Panel>
        <Panel
          title="Heat Sessions"
          subTitle="By pain location since program initiation"
        >
          <LocationChart
            filter={{ partnerId: ALL_CONSUMER_ID, includeDirect: false }}
          />
        </Panel>
      </Grid>
      <Divider />
      <Grid>
        {phi && (
          <Panel title="Treatment Details">
            <TreatmentTable
              filter={{ partnerId: ALL_CONSUMER_ID, includeDirect: false }}
            />
          </Panel>
        )}
      </Grid>
    </>
  );
};
