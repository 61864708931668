import { useQuery } from "@apollo/client";
import { Filter } from "@soovu/shared/types";

import { not } from "../lib/util";
import { GET_OUTCOME_RESULTS } from "../queries/outcome.queries";
import { OutcomeResultResponse } from "../types/outcome-result.types";

export function useOutcomeData(filter: Filter | undefined): {
  loading?: boolean;
  data?: OutcomeResultResponse;
} {
  const { data, loading } = useQuery<{
    outcomeResult: OutcomeResultResponse;
  }>(GET_OUTCOME_RESULTS, {
    variables: {
      filter,
    },
  });

  if (loading || !data) {
    return {
      loading,
    };
  }

  const enrollment: any = !not(data.outcomeResult.pain.enrollment)
    ? Math.floor(data.outcomeResult.pain.enrollment * 10) / 10
    : null;

  return {
    data: {
      ...data.outcomeResult,
      pain: {
        ...data.outcomeResult.pain,
        enrollment,
      },
    },
  };
}
