import { useQuery } from "@apollo/client";
import React, { useMemo } from "react";

import { AgesChart } from "../components/AgesChart";
import { Box } from "../components/Box";
import CompaniesTable from "../components/CompaniesTable";
import { CompletionsChart } from "../components/CompletionsChart";
import { EngagementLineChart } from "../components/EngagementLineChart";
import { Grid } from "../components/Grid";
import { MonthlyUsageTable } from "../components/MonthlyUsageTable";
import { Panel } from "../components/Panel";
import { Search } from "../components/Search";
import { UserCount } from "../components/UserCount";
import { useTitle } from "../hooks/useTitle";
import { COUNT_TREATMENTS } from "../queries/treatment.queries";
import { COUNT_USERS } from "../queries/user.queries";
import { TreatmentCountsResponse } from "../types/treatment.types";
import { UserCountsResponse } from "../types/user.types";

export const Home = () => {
  useTitle("Home");

  const { data: userData, loading: userLoading } = useQuery<{
    userCounts: UserCountsResponse[];
  }>(COUNT_USERS);

  const { data: treatmentData, loading: treatmentLoading } = useQuery<{
    treatmentCounts: TreatmentCountsResponse;
  }>(COUNT_TREATMENTS);

  const userCounts = useMemo(() => {
    const counts = {
      corp: 0,
      corpMonth: 0,
      d2c: 0,
      d2cMonth: 0,
    };

    userData?.userCounts.forEach((response) => {
      if (response.partnerId === "d2c") {
        counts.d2c += response.count;
        counts.d2cMonth += response.thisMonth;
      } else {
        counts.corp += response.count;
        counts.corpMonth += response.thisMonth;
      }
    });

    return counts;
  }, [userData]);

  const treatmentCount = useMemo(
    () => treatmentData?.treatmentCounts,
    [treatmentData],
  );

  return (
    <>
      <Search />
      <Box as="h1">Home Dashboard</Box>
      <Grid>
        <UserCount
          count={userCounts.corp}
          label="Corp. Enroll"
          loading={userLoading}
          monthly={userCounts.corpMonth}
        />
        <UserCount
          count={userCounts.d2c}
          label="DTC Enroll"
          loading={userLoading}
          monthly={userCounts.d2cMonth}
        />
        <UserCount
          count={treatmentCount?.count}
          label="Total Treatments"
          loading={treatmentLoading}
          monthly={treatmentCount?.thisMonth}
        />
      </Grid>
      <Grid>
        <Panel
          title="Enrollments"
          subTitle="Total and monthly enrollments for all organization types"
        >
          <CompaniesTable includeDirect={true} />
        </Panel>
        <Panel
          title="Engagement"
          subTitle="% users that have had one or more HeatWave sessions per month"
        >
          <EngagementLineChart />
        </Panel>
      </Grid>
      <Grid>
        <Panel title="Total Completions">
          <CompletionsChart />
        </Panel>
        {/*<Panel title="Total Gender">*/}
        {/*  <GenderChart />*/}
        {/*</Panel>*/}
        <Panel title="Total Age">
          <AgesChart />
        </Panel>
      </Grid>
      <Grid>
        <Panel title="Monthly Usage Reports">
          <MonthlyUsageTable />
        </Panel>
      </Grid>
    </>
  );
};
