import { DIRECT_TO_CONSUMER_ID, SUPER_ADMIN } from "@soovu/shared/constants";

import { ChartPoints } from "../types/common.types";

export const generatePercent = (counts: number[], totals: number[]): number => {
  return Math.round((sum(counts) / sum(totals) || 0) * 100);
};

export function getCurrentMonth(dateString?: string) {
  // date strings tend to be "2023-01-01" format, but needs to be "2023/01/01" format (JS is CRAZY)
  dateString = dateString?.replace(/-/g, "/");
  const date = dateString ? new Date(dateString) : new Date();
  return date.toLocaleString("default", { month: "short" });
}

export const getPartnerPath = (partnerId: string) => {
  return partnerId === DIRECT_TO_CONSUMER_ID
    ? "dtc"
    : `/corporate/${partnerId}`;
};

export function not(test: unknown) {
  return test === undefined || test === null;
}

export function percent(num: number, total: number) {
  if (!total) {
    return 0;
  }

  return Math.round((num / total) * 100);
}

export function startCase(str: string) {
  const result = str.replace(/([A-Z])/g, " $1");
  return result.charAt(0).toUpperCase() + result.slice(1);
}

export function sum(arr: number[]) {
  return arr.reduce((memo, x) => memo + x, 0) || 0;
}

export function sumPoints(points: ChartPoints) {
  return points.reduce((memo, x) => memo + x[1], 0);
}

export const get_roles = (
  permissions: string[],
): [boolean, boolean, string | null] => {
  // (permissions as any) = ["nonphi:logixboard"];

  if (!!permissions && permissions.indexOf("list:partners") >= 0) {
    return [true, true, SUPER_ADMIN];
  } else {
    const [access_type, parsed_partner] = permissions[0].split(":");
    console.log(access_type, parsed_partner);
    if (!!parsed_partner && !!access_type) {
      return [
        access_type === "admin",
        access_type === "nonphi" ? false : true,
        parsed_partner,
      ];
    }
  }
  return [false, false, null];
};
