import { Filter } from "@soovu/shared/types";
import React from "react";

import { useUsageData } from "../hooks/useUsageData";

import { BarChart } from "./BarChart";
import { Loading } from "./Loading";
import { NoData } from "./NoData";
import { PieChart } from "./PieChart";

type LocationChartProps = {
  filter?: Filter;
  isPie?: boolean;
};

export const LocationChart = ({ filter, isPie }: LocationChartProps) => {
  const { labels, loading, series } = useUsageData("location", filter, {
    counts: !isPie,
    limit: 5,
    sort: true,
  });

  if (loading) {
    return <Loading />;
  }

  if (!series) {
    return <NoData />;
  }

  if (isPie) {
    return <PieChart series={series} labels={labels} />;
  }

  const barSeries = [
    {
      name: "Sessions",
      data: series,
    },
  ];

  return <BarChart series={barSeries} labels={labels} isColumn={true} />;
};
