import { useAuth0 } from "@auth0/auth0-react";
import { SUPER_ADMIN } from "@soovu/shared/constants";
import { jwtDecode } from "jwt-decode";
import React, { useContext, useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";

import "./App.css";
import Sidebar from "./components/Sidebar";
import { get_roles } from "./lib/util";
import { AllUsers } from "./pages/AllUsers";
import { ClinicalStudies } from "./pages/ClinicalStudies";
import { CompanyDetails } from "./pages/CompanyDetails";
import { Corporate } from "./pages/Corporate";
import { DirectToConsumer } from "./pages/DirectToConsumer";
import { Home } from "./pages/Home";
import { InternalTesters } from "./pages/InternalTesters";
import { UserDetails } from "./pages/UserDetails";
import { Context } from "./state/Context";
import { ACCESS } from "./types/role.types";

import { styled } from "@/stitches";

const Viewport = styled("div", {
  background: "#f1f5f9",
  flex: "1",
  overflow: "auto",
  padding: "0 16px",
  textAlign: "left",

  "@bp3": {
    padding: "32px",
  },
});

function App() {
  const [partner, setPartner] = useState<string | null>(null);
  const [phi, setPHI] = useState<boolean>(false);
  const [admin, setAdmin] = useState<boolean>(false);

  const { menuOpen } = useContext(Context);

  const {
    isAuthenticated,
    isLoading,
    loginWithRedirect,
    getAccessTokenSilently,
  } = useAuth0();

  useEffect(() => {
    const getToken = async (options: {}) => {
      const token: any = await getAccessTokenSilently(options)
        .then((token) => {
          console.log("retrieved token!");
          return token;
        })
        .catch((e) => {
          console.error("failed to get token!", e);
        });
      // console.log(token);
      const decoded: any = jwtDecode(token);
      // console.log(decoded?.permissions || null);

      const [is_admin, is_phi, partner] = get_roles(decoded?.permissions);
      // console.log("ROLE: ", decoded?.permissions, is_admin, is_phi, partner);
      if (partner !== null) {
        //|| true == true) {
        setPartner(partner as any);
        setPHI(is_phi as any);
        setAdmin(is_admin as any);
      }
    };

    if (isLoading) {
      console.log("app is loading");
      return;
    }

    if (!isAuthenticated) {
      console.log("not authenticated. redirecting to login");
      loginWithRedirect().catch();
      return;
    }

    getToken({});
  }, [isLoading, isAuthenticated, loginWithRedirect, getAccessTokenSilently]);

  // console.log(isAuthenticated);
  if (!isAuthenticated) {
    return null;
  }

  const access: ACCESS = [partner, admin, phi];
  // console.log(user, partner, admin);

  return (
    <div className="App">
      <Sidebar isOpen={menuOpen} access={access} />

      {partner === SUPER_ADMIN && admin ? (
        <Viewport>
          <Routes>
            <Route path="/" element={<Home />} />
            {partner === SUPER_ADMIN ? (
              <>
                <Route path="/users" element={<AllUsers access={access} />} />
                <Route
                  path="/user/:id"
                  element={<UserDetails access={access} />}
                />
              </>
            ) : (
              ""
            )}
            <Route
              path="/corporate/:id"
              element={<CompanyDetails access={access} />}
            />
            <Route path="/corporate" element={<Corporate />} />
            <Route
              path="/corporate/:id"
              element={<CompanyDetails access={access} />}
            />
            <Route
              path="/corporate/user/:id"
              element={<UserDetails access={access} />}
            />
            <Route path="/dtc" element={<DirectToConsumer access={access} />} />
            <Route
              path="/dtc/user/:id"
              element={<UserDetails access={access} />}
            />
            <Route path="/testers" element={<InternalTesters />} />
            <Route path="/clinical" element={<ClinicalStudies />} />
          </Routes>
        </Viewport>
      ) : partner !== null ? (
        <Viewport>
          <Routes>
            <Route path="/" element={<CompanyDetails access={access} />} />
            <Route
              path="/corporate/user/:id"
              element={<UserDetails access={access} />}
            />
          </Routes>
        </Viewport>
      ) : (
        ""
      )}
    </div>
  );
}

export default App;
