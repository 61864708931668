import { gql } from "@apollo/client";

export const GENDER_COUNTS = gql`
  query GetGenders($filter: Filter) {
    genderCounts(filter: $filter) {
      anon
      female
      male
      total
    }
  }
`;
