import { gql } from "@apollo/client";

export const COMPLETION_COUNTS = gql`
  query GetCompletions($filter: Filter) {
    completionCounts(filter: $filter) {
      error
      lowBattery
      normal
      tempLimited
      total
      userAbort
    }
  }
`;
