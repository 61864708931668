import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { BrowserRouter } from "react-router-dom";

import App from "./App";
import ApolloProviderWithClient from "./lib/ApolloClient";
import Auth0ProviderWithHistory from "./lib/Auth0Provider";
import reportWebVitals from "./reportWebVitals";
import { ContextProvider } from "./state/Context";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Auth0ProviderWithHistory>
        <ApolloProviderWithClient>
          <ContextProvider>
            <App />
          </ContextProvider>
        </ApolloProviderWithClient>
      </Auth0ProviderWithHistory>
    </BrowserRouter>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
