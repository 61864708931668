import { Box } from "./Box";

import { styled } from "@/stitches";

export const Grid = styled(Box, {
  display: "flex",
  flexDirection: "column",
  margin: "16px 0",
  rowGap: "16px",

  "@bp3": {
    columnGap: "16px",
    flexDirection: "row",
    margin: "32px 0",
  },
});
