export async function download_usage_report(
  event: React.MouseEvent<HTMLAnchorElement>,
  getAccessTokenSilently: Function,
  month_year: string,
  partner: string = "all",
) {
  event.stopPropagation();
  const token = await getAccessTokenSilently();

  console.log(`Downloading report for: ${month_year}`);
  const filename = `${month_year}_usage_report.csv`;

  fetch(`${process.env.REACT_APP_SERVER_URI}/download/${partner}/${filename}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/pdf",
      authorization: `Bearer ${token}`,
    },
  })
    .then((response) => response.blob())
    .then((blob) => {
      // Create blob link to download
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      if (link.parentNode) {
        link.parentNode.removeChild(link);
      }
    });
}
