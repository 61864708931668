export const BEGINNING_OF_CENTURY = new Date("2001-01-01T00:00:00Z");
export const BEGINNING_OF_TIME = new Date("1970-01-01T00:00:00Z");

export const BODY_PART_DICTIONARY = {
  1: "",
  2: "Neck",
  3: "Front Shoulder",
  4: "Right Arm",
  5: "Left Arm",
  6: "Chest",
  7: "Right Arm",
  8: "Left Arm",
  9: "Abdomen",
  10: "Upper Leg",
  11: "Knee",
  12: "Lower Leg",
  13: "Foot / Ankle",
  14: "",
  15: "Neck",
  16: "Back Shoulder",
  17: "Left Arm",
  18: "Right Arm",
  19: "Back",
  20: "Left Arm",
  21: "Right Arm",
  22: "Lower Back",
  23: "Upper Leg",
  24: "Knee",
  25: "Lower Leg",
  26: "Ankle / Foot",
};

// --- GROUPS ---
// neck [2, 15]
// shoulder [3, 16]
// arm [4, 5, 7, 8, 17, 18, 20, 21]
// chest [6]
// abdomen [9]
// back [19, 22]
// leg [10, 12, 23, 25]
// knee [11, 24]
// foot/ankle [13, 26]

export const SUPER_ADMIN = "super";
export const ALL_CONSUMER_ID = "all";
export const DIRECT_TO_CONSUMER_ID = "d2c";