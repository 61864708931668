import { Filter } from "@soovu/shared/types";
import React from "react";

import { useUsageData } from "../hooks/useUsageData";

import { Loading } from "./Loading";
import { NoData } from "./NoData";
import { PieChart } from "./PieChart";

type DurationChartProps = {
  filter?: Filter;
};

export const DurationChart = ({ filter }: DurationChartProps) => {
  const { labels, loading, series } = useUsageData("duration", filter);

  if (loading) {
    return <Loading />;
  }

  if (!series) {
    return <NoData />;
  }

  return <PieChart series={series} labels={labels} />;
};
