import React, { Dispatch, useState } from "react";

type ContextProps = {
  children: string | React.ReactNode;
};

type Props = {
  menuOpen: boolean;
  setMenuOpen: Dispatch<boolean>;
};

export const Context = React.createContext({
  menuOpen: false,
  setMenuOpen: () => undefined,
} as Props);

export const ContextProvider = ({ children }: ContextProps) => {
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <Context.Provider value={{ menuOpen, setMenuOpen }}>
      {children}
    </Context.Provider>
  );
};
