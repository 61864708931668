import { useQuery } from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";
import { Filter } from "@soovu/shared/types";
import { createColumnHelper, PaginationState } from "@tanstack/react-table";
import React, { useState } from "react";

import { download_usage_report } from "../lib/download-usage-report";
import { GET_MONTHLY_USAGE_REPORTS } from "../queries/usage.queries";
import { GQLResponse } from "../types/common.types";
import { MonthlyUsageReports } from "../types/usage.types";

import { Box } from "./Box";
import Table from "./Table";

interface MonthlyUsageTableProps {
  filter?: Filter;
}

export const MonthlyUsageTable = ({ filter }: MonthlyUsageTableProps) => {
  const { getAccessTokenSilently } = useAuth0();

  const columnHelper = createColumnHelper<MonthlyUsageReports>();

  const columnDictionary = {
    monthYear: columnHelper.accessor("monthYear", {
      cell: (cell) => {
        const month_year: string = cell.getValue().toString();
        const [year, month_value] = month_year.split("-");
        const month = new Date(
          2000,
          parseInt(month_value) - 1,
          1,
        ).toLocaleString("default", { month: "long" });

        const monthly_report = `${month} ${year}`;

        return (
          <>
            <a
              href="#"
              onClick={(event: React.MouseEvent<HTMLAnchorElement>) =>
                download_usage_report(event, getAccessTokenSilently, month_year)
              }
            >
              {monthly_report}
            </a>
          </>
        );
      },
      header: "Date",
    }),
    totalUsers: columnHelper.accessor("totalUsers", {
      header: () => "# Users",
    }),
    totalSessions: columnHelper.accessor("totalSessions", {
      header: "# Sessions",
    }),
  };

  const columns = [
    columnDictionary.monthYear,
    columnDictionary.totalUsers,
    columnDictionary.totalSessions,
  ];

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const pagination = React.useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize],
  );

  const { data, loading } = useQuery<{
    monthlyUsageReports: GQLResponse<MonthlyUsageReports>;
  }>(GET_MONTHLY_USAGE_REPORTS, {
    variables: {
      pagination: {
        page: pageIndex + 1,
        pageSize,
      },
      filter,
    },
  });

  if (loading || !data) {
    return <Box>...loading</Box>;
  }

  return (
    <Table
      columns={columns}
      data={data.monthlyUsageReports}
      onPaginationChange={setPagination}
      pagination={pagination}
    />
  );
};
