import { useQuery } from "@apollo/client";
import { Filter } from "@soovu/shared/types";
import React from "react";

import { COMPLETION_COUNTS } from "../queries/completion.queries";
import { CompletionCountsResponse } from "../types/completion.types";

import { Loading } from "./Loading";
import { NoData } from "./NoData";
import { PieChart } from "./PieChart";

type CompletionsChartProps = {
  filter?: Filter;
};

export const CompletionsChart = ({ filter }: CompletionsChartProps) => {
  const labels = [];
  const series = [];

  const { data, loading } = useQuery<{
    completionCounts: CompletionCountsResponse;
  }>(COMPLETION_COUNTS, { variables: { filter } });

  if (loading) {
    return <Loading />;
  }

  if (!data) {
    return <NoData />;
  }

  const { completionCounts } = data;
  const { error, lowBattery, normal, total, userAbort } = completionCounts;

  if (normal) {
    labels.push("Completed");
    series.push(Math.round((normal / total) * 100));
  }

  if (error) {
    labels.push("Unknown Error");
    series.push(Math.round((error / total) * 100));
  }

  if (userAbort) {
    labels.push("User Abort");
    series.push(Math.round((userAbort / total) * 100));
  }

  if (lowBattery) {
    labels.push("Low Batt");
    series.push(Math.round((lowBattery / total) * 100));
  }

  // if (tempLimited) {
  //   labels.push("Temp Limited");
  //   series.push(Math.ceil((tempLimited / total) * 100));
  // }

  return <PieChart series={series} labels={labels} isDonut={true} />;
};
