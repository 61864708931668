import { Filter } from "@soovu/shared/types";
import React from "react";

import { useOutcomeData } from "../hooks/useOutcomeData";
import { not } from "../lib/util";

import { BarChart } from "./BarChart";
import { Loading } from "./Loading";
import { NoData } from "./NoData";

export const QualityOfLifeChart = ({ filter }: { filter?: Filter }) => {
  const labels: string[] = [];
  const series: number[] = [];

  const { data, loading } = useOutcomeData(filter);

  if (loading) {
    return <Loading />;
  }

  if (!data) {
    return <NoData />;
  }

  const {
    concentration,
    dayToDay,
    enjoyment,
    outsideOfHome,
    recreational,
    sleep,
    socializing,
  } = data.interfere;

  if (!not(concentration)) {
    labels.push("Concentration");
    series.push(Math.round(concentration * 100));
  }

  if (!not(dayToDay)) {
    labels.push("Daily");
    series.push(Math.round(dayToDay * 100));
  }

  if (!not(enjoyment)) {
    labels.push("Enjoyment");
    series.push(Math.round(enjoyment * 100));
  }

  if (!not(outsideOfHome)) {
    labels.push("Outside Home");
    series.push(Math.round(outsideOfHome * 100));
  }

  if (!not(recreational)) {
    labels.push("Recreational");
    series.push(Math.round(recreational * 100));
  }

  if (!not(sleep)) {
    labels.push("Sleep");
    series.push(Math.round(sleep * 100));
  }

  if (!not(socializing)) {
    labels.push("Socializing");
    series.push(Math.round(socializing * 100));
  }

  return (
    <BarChart
      series={[{ name: "Improved", data: series }]}
      labels={labels}
      isColumn={true}
      isPercent={true}
    />
  );
};
