import { Filter } from "@soovu/shared/types";
import React from "react";

import { AveragePain } from "../components/AveragePain";
import { AverageReduction } from "../components/AverageReduction";
import { Grid } from "../components/Grid";
import { PainScoreChart } from "../components/PainScoreChart";
import { Panel } from "../components/Panel";
import { QualityOfLifeChart } from "../components/QualityOfLifeChart";
import { Satisfaction } from "../components/Satisfaction";

type OutcomeTemplateProps = {
  filter: Filter;
};

export const OutcomeTemplate = ({ filter }: OutcomeTemplateProps) => {
  return (
    <>
      <Grid>
        <Panel>
          <AveragePain filter={filter} />
        </Panel>
        <Panel>
          <AverageReduction filter={filter} />
        </Panel>
        <Panel>
          <Satisfaction filter={filter} />
        </Panel>
      </Grid>
      <Grid>
        <Panel
          title="Avg. Participant Pain Score"
          subTitle="Since program initiation"
        >
          <PainScoreChart filter={filter} />
        </Panel>
        <Panel
          title="Quality of Life Improvements"
          subTitle="Due to decrease in pain interference"
        >
          <QualityOfLifeChart filter={filter} />
        </Panel>
      </Grid>
    </>
  );
};
