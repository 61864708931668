import { useEffect } from "react";

const defaultTitle = "Soovu Pain Relief System | Admin Dashboard";

export function useTitle(title: string | undefined) {
  useEffect(() => {
    const prevTitle = document.title;
    document.title = title ? `${title} | ${defaultTitle}` : defaultTitle;
    return () => {
      document.title = prevTitle;
    };
  });
}
