import { Filter } from "@soovu/shared/types";
import React from "react";

import { useOutcomeData } from "../hooks/useOutcomeData";
import { not } from "../lib/util";

import { Box } from "./Box";
import { Loading } from "./Loading";
import { NoData } from "./NoData";

import { styled } from "@/stitches";

type SatisfactionProps = {
  filter?: Filter;
};

const Wrapper = styled(Box, {
  display: "flex",
  padding: "26px 34px 20px",
});

const Title = styled("h4", {
  fontWeight: 400,
  fontSize: 16,
  margin: "0 0 5px 0",
});

const Value = styled(Box, {
  borderRadius: 4,
  color: "#ffffff",
  flex: 1,
  fontWeight: 400,
  fontSize: 14,
  padding: "4px 14px",
  textAlign: "center",
});

export const Satisfaction = ({ filter }: SatisfactionProps) => {
  const { data, loading } = useOutcomeData(filter);

  if (loading) {
    return <Loading />;
  }

  if (!data) {
    return <NoData />;
  }

  const { nps, satisfaction } = data;

  if (not(nps) && not(satisfaction)) {
    return <NoData />;
  }

  return (
    <Wrapper>
      {!not(satisfaction) && (
        <Box
          css={{
            display: "flex",
            flexDirection: "column",
            flex: "1 1 0",
            minWidth: 0,
            alignItems: "center",
          }}
        >
          <Title>Satisfaction</Title>
          <Value css={{ background: "#EF4F25" }}>
            {Math.round(data.satisfaction * 100)}%
          </Value>
        </Box>
      )}

      {!not(nps) && (
        <Box
          css={{
            display: "flex",
            flexDirection: "column",
            flex: "1 1 0",
            minWidth: 0,
            alignItems: "center",
          }}
        >
          <Title>NPS</Title>
          <Value css={{ background: "#18C0F0" }}>
            {Math.round(data.nps * 100)}%
          </Value>
        </Box>
      )}
    </Wrapper>
  );
};
