import React from "react";

import { Box } from "./Box";

import { styled } from "@/stitches";

interface PanelProps {
  children?: string | React.ReactNode;
  subTitle?: string | React.ReactNode;
  title?: string | React.ReactNode;
  buttons?: string | React.ReactNode;
}

const Wrapper = styled(Box, {
  background: "white",
  border: "1px solid #E7E7E7",
  borderRadius: "8px",
  boxShadow:
    "0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06)",
  display: "flex",
  flex: "1 1 0",
  flexDirection: "column",
  minWidth: 0,
  position: "relative",
});

const TitleStyled = styled("h3", {
  borderBottom: "1px solid #E7E7E7",
  lineHeight: 1.45,
  padding: "20px 32px 18px",
  position: "relative",
});

const ButtonsStyled = styled("div", {
  fontSize: "14px",
  fontWeight: 325,
  position: "absolute",
  right: "32px",
  top: "20px",
  display: "flex",
  height: "30px",
  a: {
    width: "30px",
  },
});

const SubTitleStyled = styled("div", {
  fontSize: "14px",
  fontWeight: 325,
});

const Content = styled(Box, {
  flex: 1,
  minHeight: 50,
  overflowX: "auto",
  position: "relative",
});

export const Panel = ({ children, subTitle, title, buttons }: PanelProps) => {
  return (
    <Wrapper>
      {title && (
        <TitleStyled>
          {title}
          {subTitle && <SubTitleStyled>{subTitle}</SubTitleStyled>}
          {buttons && <ButtonsStyled>{buttons}</ButtonsStyled>}
        </TitleStyled>
      )}
      <Content>{children}</Content>
    </Wrapper>
  );
};
