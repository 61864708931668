import { useLazyQuery } from "@apollo/client";
import {
  Bars3Icon,
  MagnifyingGlassIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { DIRECT_TO_CONSUMER_ID } from "@soovu/shared/constants";
import React, {
  ChangeEvent,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Link } from "react-router-dom";

import { SEARCH_USERS } from "../queries/user.queries";
import { Context } from "../state/Context";
import { User } from "../types/user.types";

import { Box } from "./Box";

import { styled } from "@/stitches";

const Wrapper = styled(Box, {
  background: "white",
  boxShadow:
    "0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06)",
  display: "flex",
  flex: "1 1 0",
  flexDirection: "column",
  minWidth: 0,
  position: "relative",
  margin: "0 -16px 32px",

  "@bp3": {
    border: "1px solid #E7E7E7",
    borderRadius: "8px",
    margin: 0,
  },
});

const Content = styled(Box, {
  padding: "26px 0 23px",
  position: "relative",
});

const StyledInput = styled("input", {
  border: "none",
  display: "none",
  fontFamily: "'Gotham Rounded', sans-serif",
  fontSize: 16,
  fontWeight: 300,
  padding: 0,
  width: "100%",
  "&::placeholder": {
    color: "#8B90A0",
  },

  "@bp3": {
    display: "flex",
  },
});

const StyledInputWrapper = styled("div", {
  display: "flex",
  justifyContent: "space-between",
  padding: "0",

  "@bp3": {
    padding: "0 32px",
  },

  variants: {
    open: {
      true: {
        justifyContent: "flex-start",

        "& .menu-trigger": {
          display: "none",
        },

        "& input": {
          display: "flex",
        },
      },
    },
  },
});

const StyledIcon = styled("span", {
  color: "#8B90A0",
  display: "flex",
  flex: "0 0 20px",
  padding: "0 20px",

  "@bp3": {
    marginRight: 19,
    padding: 0,
  },
});

const StyledBarIcon = styled(StyledIcon, {
  color: "#232735",

  "@bp3": {
    display: "none",
  },
});

const Viewport = styled("div", {
  background: "white",
  borderRadius: "0 0 8px 8px",
  boxShadow:
    "0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06)",
  boxSizing: "border-box",
  flexDirection: "column",
  padding: "20px 0",
  position: "fixed",
  zIndex: 2,
});

const Divider = styled("hr", {
  border: "1px solid #E2E8F0",
  display: "none",
  margin: "32px 0",

  "@bp3": {
    display: "block",
  },
});

export const Search = () => {
  const { menuOpen, setMenuOpen } = useContext(Context);
  const wrapperRef = useRef<HTMLInputElement>(null);
  const [width, setWidth] = useState(0);
  const [showResults, setShowResults] = useState(false);
  const [showInput, setShowInput] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [search, setSearch] = useState("");

  const change = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value ?? "";
    const parentPos = wrapperRef.current?.getBoundingClientRect();

    const doSearch = value.length > 1;

    setInputValue(value);
    setShowResults(doSearch);

    if (parentPos && doSearch) {
      setWidth(parentPos.width);
      setSearch(value);
    }
  };

  const reset = () => {
    setShowResults(false);
    setInputValue("");
  };

  const [loadUsers, { data, loading }] = useLazyQuery<{ searchUsers: User[] }>(
    SEARCH_USERS,
    {
      variables: {
        search: search,
      },
    },
  );

  useEffect(() => {
    if (showResults) {
      loadUsers().catch();
    }
  }, [loadUsers, showResults]);

  const renderUser = (user: User) => {
    const prefix =
      user.partnerId === DIRECT_TO_CONSUMER_ID ? "dtc" : "corporate";
    return (
      <Link key={user.id} to={`/${prefix}/user/${user.id}`} onClick={reset}>
        <Box
          css={{
            display: "flex",
            flexDirection: "column",
            gap: 8,
            padding: "10px 32px",
            "&:hover": {
              background: "#F8FAFC",
            },
          }}
          key={user.id}
        >
          <Box css={{ color: "#EF4F25;" }}>{user.email}</Box>
          <Box css={{ color: "#505565" }}>
            {user.nameFirst} {user.nameLast}{" "}
            <Box as="span" css={{ color: "#CBD5E1" }}>
              |
            </Box>{" "}
            {user.Partner.name}{" "}
            <Box as="span" css={{ color: "#CBD5E1" }}>
              |
            </Box>{" "}
            {user.altId}
          </Box>
        </Box>
      </Link>
    );
  };

  return (
    <>
      <Wrapper>
        <Content ref={wrapperRef}>
          <StyledInputWrapper open={showInput}>
            <StyledBarIcon
              className="menu-trigger"
              onClick={() => setMenuOpen(!menuOpen)}
            >
              <Bars3Icon strokeWidth={2} />
            </StyledBarIcon>
            <StyledIcon onClick={() => setShowInput(!showInput)}>
              <MagnifyingGlassIcon strokeWidth={2} />
            </StyledIcon>
            <StyledInput
              type="text"
              placeholder="Search Name, Email, or Company..."
              onChange={change}
              value={inputValue}
              name="search"
            />
            {showResults && (
              <StyledIcon
                onClick={reset}
                css={{ cursor: "pointer", margin: "0 20px" }}
              >
                <XMarkIcon strokeWidth={2} />
              </StyledIcon>
            )}
          </StyledInputWrapper>
          <Viewport
            style={{
              display: showResults ? "flex" : "none",
              width,
            }}
          >
            {loading && <Box>Loading...</Box>}
            {!loading && !data?.searchUsers?.length && <Box>No results.</Box>}
            {data && data.searchUsers.map(renderUser)}
          </Viewport>
        </Content>
      </Wrapper>
      <Divider />
    </>
  );
};
