import { useQuery } from "@apollo/client";
import { DIRECT_TO_CONSUMER_ID, SUPER_ADMIN } from "@soovu/shared/constants";
import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

import { Box } from "../components/Box";
import { BreadCrumb } from "../components/BreadCrumb";
import { CompletionsChart } from "../components/CompletionsChart";
import { DurationChart } from "../components/DurationChart";
import { GainChart } from "../components/GainChart";
import { Grid } from "../components/Grid";
import { LocationChart } from "../components/LocationChart";
import { Panel } from "../components/Panel";
import { RatingChart } from "../components/RatingChart";
import { Search } from "../components/Search";
import { Tabs } from "../components/Tabs";
import { TreatmentTable } from "../components/TreatmentTable";
import { UserCount } from "../components/UserCount";
import { UserDetailsTable } from "../components/UserDetailsTable";
import { WaveformChart } from "../components/WaveformChart";
import { useTitle } from "../hooks/useTitle";
import { COUNT_TREATMENTS } from "../queries/treatment.queries";
import { GET_USER } from "../queries/user.queries";
import { OutcomeTemplate } from "../templates/outcome.template";
import { ACCESS } from "../types/role.types";
import { TreatmentCountsResponse } from "../types/treatment.types";
import { User } from "../types/user.types";

export const UserDetails = ({ access }: { access: ACCESS }) => {
  const { id } = useParams();
  const [title, setTitle] = useState<string | undefined>();

  // eslint-disable-next-line no-unused-vars
  const [partner, admin, phi] = access;

  useTitle(title);

  const { data, loading } = useQuery<{ user: User | undefined }>(GET_USER, {
    variables: {
      id,
    },
  });

  const { data: treatmentData, loading: treatmentLoading } = useQuery<{
    treatmentCounts: TreatmentCountsResponse;
  }>(COUNT_TREATMENTS, {
    variables: {
      filter: {
        userId: id,
      },
    },
  });

  const treatmentCount = treatmentData?.treatmentCounts;

  const userData = data?.user;

  const breadCrumbParts = useMemo(() => {
    const parts = [];

    if (data && userData) {
      const isDTC = userData.Partner.partnerUrl === DIRECT_TO_CONSUMER_ID;

      parts.push({
        label: isDTC ? "Direct to Consumer" : "Corporate Enrollment",
        path: isDTC ? "/dtc" : "/corporate",
      });

      if (!isDTC) {
        parts.push({
          label: userData.Partner.name,
          path: `/corporate/${userData.Partner.partnerUrl}`,
        });
      }

      parts.push({
        label: `${userData.nameFirst} ${userData.nameLast}`,
        path: `/user/${id}`,
      });
    }

    return parts;
  }, [data, id, userData]);

  useEffect(() => {
    if (userData) {
      setTitle(`${userData?.nameFirst} ${userData?.nameLast}`);
    }
  }, [userData]);

  const udid = parseInt(id ?? "0") + 1000;

  return (
    <>
      <Search />
      {!!partner && partner === SUPER_ADMIN && (
        <Grid>
          <Panel>
            <BreadCrumb parts={breadCrumbParts} />
          </Panel>
        </Grid>
      )}
      <Box as="h1">
        {userData?.nameFirst} {userData?.nameLast}
        {userData
          ? `: ${(userData?.altId ? `${parseInt(userData?.altId) + 1000} / ` : "") + udid}`
          : ""}
      </Box>
      <Box>
        <Tabs
          description="User registration, usage, and surveys"
          tabs={[
            {
              id: "registration",
              label: "Registration",
              content: (
                <>
                  <Grid>
                    <Box as="h2">Registration Details</Box>
                  </Grid>
                  <Grid>
                    <Panel>
                      <UserDetailsTable loading={loading} user={userData} />
                    </Panel>
                  </Grid>
                </>
              ),
            },
            {
              id: "usage",
              label: "Usage",
              content: (
                <>
                  <Grid>
                    <UserCount
                      count={treatmentCount?.count}
                      label="Total Treatments"
                      loading={treatmentLoading}
                      monthly={treatmentCount?.thisMonth}
                    />
                  </Grid>
                  <Grid>
                    <Panel title="Completed Sessions">
                      <CompletionsChart filter={{ userId: id }} />
                    </Panel>
                    <Panel title="Location">
                      <LocationChart filter={{ userId: id }} isPie={true} />
                    </Panel>
                    <Panel title="Pain Rating Change">
                      <RatingChart filter={{ userId: id }} />
                    </Panel>
                  </Grid>
                  <Grid>
                    <Panel title="Heat Profile">
                      <WaveformChart filter={{ userId: id }} />
                    </Panel>
                    <Panel title="Duration">
                      <DurationChart filter={{ userId: id }} />
                    </Panel>
                    <Panel title="Temperature">
                      <GainChart filter={{ userId: id }} />
                    </Panel>
                  </Grid>
                  <Grid>
                    <Panel title="Treatment Details">
                      <TreatmentTable filter={{ userId: id }} />
                    </Panel>
                  </Grid>
                </>
              ),
            },
            {
              id: "outcomes",
              label: "Outcomes",
              content: <OutcomeTemplate filter={{ userId: id }} />,
            },
          ]}
        />
      </Box>
    </>
  );
};
