import { useQuery } from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";
import {
  ArrowDownOnSquareIcon,
  ArrowDownOnSquareStackIcon,
} from "@heroicons/react/24/outline";
import { DIRECT_TO_CONSUMER_ID } from "@soovu/shared/constants";
import React from "react";

import { ActiveUsageTable } from "../components/ActiveUsageTable";
import { AgesChart } from "../components/AgesChart";
import { Box } from "../components/Box";
import { EngagementBarChart } from "../components/EngagementBarChart";
import { EnrollmentChart } from "../components/EnrollmentChart";
import { GainChart } from "../components/GainChart";
import { GenderChart } from "../components/GenderChart";
import { Grid } from "../components/Grid";
import { ImprovementChart } from "../components/ImprovementChart";
import { LocationChart } from "../components/LocationChart";
import { PainTypeChart } from "../components/PainTypeChart";
import { Panel } from "../components/Panel";
import { RatingChart } from "../components/RatingChart";
import { Search } from "../components/Search";
import { Tabs } from "../components/Tabs";
import { UserCount } from "../components/UserCount";
import { UserTable } from "../components/UserTable";
import { WaveformChart } from "../components/WaveformChart";
import { useTitle } from "../hooks/useTitle";
import { download_usage_report } from "../lib/download-usage-report";
import { COUNT_TREATMENTS } from "../queries/treatment.queries";
import { COUNT_USERS } from "../queries/user.queries";
import { OutcomeTemplate } from "../templates/outcome.template";
import { ACCESS } from "../types/role.types";
import { TreatmentCountsResponse } from "../types/treatment.types";
import { UserCountsResponse } from "../types/user.types";

import { styled } from "@/stitches";

const Divider = styled("hr", {
  border: "1px solid #E2E8F0",
  display: "none",
  margin: "32px 0",
});

export const DirectToConsumer = ({ access }: { access: ACCESS }) => {
  const { getAccessTokenSilently } = useAuth0();

  // eslint-disable-next-line no-unused-vars
  const [partner, admin, phi] = access;

  useTitle("Direct to Consumer");

  const { data: userData, loading: userLoading } = useQuery<{
    userCounts: UserCountsResponse[];
  }>(COUNT_USERS, {
    variables: {
      filter: {
        partnerId: DIRECT_TO_CONSUMER_ID,
      },
    },
  });

  const { data: treatmentData, loading: treatmentLoading } = useQuery<{
    treatmentCounts: TreatmentCountsResponse;
  }>(COUNT_TREATMENTS, {
    variables: {
      filter: {
        partnerId: DIRECT_TO_CONSUMER_ID,
      },
    },
  });

  const userCount = userData?.userCounts[0];
  const treatmentCount = treatmentData?.treatmentCounts;

  return (
    <>
      <Search />
      <Box as="h1">Direct to Consumer Enrollment</Box>
      <Grid>
        <UserCount
          count={userCount?.count}
          label="DTC Enrolled"
          loading={userLoading}
          monthly={userCount?.thisMonth}
        />
        <UserCount
          count={treatmentCount?.count}
          label="Total Treatments"
          loading={treatmentLoading}
          monthly={treatmentCount?.thisMonth}
        />
      </Grid>

      <Divider />
      <Box as="h2">Report Visuals</Box>

      <Tabs
        description="Different types of reports"
        tabs={[
          {
            id: "enrollment",
            label: "Enrollment",
            content: (
              <>
                <Grid>
                  <Panel title="Monthly Enrollments">
                    <EnrollmentChart
                      filter={{ partnerId: DIRECT_TO_CONSUMER_ID }}
                    />
                  </Panel>
                  <Panel title="Pain Types">
                    <PainTypeChart
                      filter={{ partnerId: DIRECT_TO_CONSUMER_ID }}
                    />
                  </Panel>
                </Grid>
                <Grid>
                  <Panel title="Gender">
                    <GenderChart
                      filter={{ partnerId: DIRECT_TO_CONSUMER_ID }}
                    />
                  </Panel>
                  <Panel title="Age">
                    <AgesChart filter={{ partnerId: DIRECT_TO_CONSUMER_ID }} />
                  </Panel>
                </Grid>
                <Divider />
                <Box as="h2">Individuals</Box>
                <Grid>
                  <Panel title="Consumer Registrations">
                    <UserTable filter={{ partnerId: DIRECT_TO_CONSUMER_ID }} />
                  </Panel>
                </Grid>
              </>
            ),
          },
          {
            id: "engagement",
            label: "Engagement",
            content: (
              <>
                <Grid>
                  <Panel
                    title="Engaged by Month"
                    subTitle="% users that have had one or more HeatWave sessions per month"
                  >
                    <EngagementBarChart
                      filter={{ partnerId: DIRECT_TO_CONSUMER_ID }}
                    />
                  </Panel>
                  <Panel
                    title="Heat Sessions"
                    subTitle="By pain location since program initiation"
                  >
                    <LocationChart
                      filter={{ partnerId: DIRECT_TO_CONSUMER_ID }}
                    />
                  </Panel>
                  <Panel title="Improvement">
                    <ImprovementChart
                      filter={{ partnerId: DIRECT_TO_CONSUMER_ID }}
                    ></ImprovementChart>
                  </Panel>
                </Grid>
                <Grid>
                  <Panel title="Distribution Temperature">
                    <GainChart filter={{ partnerId: DIRECT_TO_CONSUMER_ID }} />
                  </Panel>
                  <Panel title="Distribution Heat Profiles">
                    <WaveformChart
                      filter={{ partnerId: DIRECT_TO_CONSUMER_ID }}
                    />
                  </Panel>
                </Grid>
                <Divider />
                <Grid>
                  {phi && (
                    <Panel
                      title="Usage Last 30 Days"
                      buttons={
                        <>
                          <a
                            href="#"
                            onClick={(
                              event: React.MouseEvent<HTMLAnchorElement>,
                            ) =>
                              download_usage_report(
                                event,
                                getAccessTokenSilently,
                                "30day",
                                "d2c",
                              )
                            }
                          >
                            <ArrowDownOnSquareIcon strokeWidth={2} />
                          </a>
                          <a
                            href="#"
                            onClick={(
                              event: React.MouseEvent<HTMLAnchorElement>,
                            ) =>
                              download_usage_report(
                                event,
                                getAccessTokenSilently,
                                "full",
                                "d2c",
                              )
                            }
                          >
                            <ArrowDownOnSquareStackIcon strokeWidth={2} />
                          </a>
                        </>
                      }
                    >
                      <ActiveUsageTable
                        filter={{ partnerId: DIRECT_TO_CONSUMER_ID }}
                      />
                    </Panel>
                  )}
                  {!phi && (
                    <>
                      <Panel title="Pain Rating Change">
                        <RatingChart
                          filter={{ partnerId: DIRECT_TO_CONSUMER_ID }}
                        />
                      </Panel>
                      <Panel></Panel>
                    </>
                  )}
                </Grid>
              </>
            ),
          },
          {
            id: "outcomes",
            label: "Outcomes",
            content: (
              <OutcomeTemplate filter={{ partnerId: DIRECT_TO_CONSUMER_ID }} />
            ),
          },
        ]}
      />
    </>
  );
};
