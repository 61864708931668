import { gql } from "@apollo/client";

export const COUNT_USERS = gql`
  query CountAndGroupUsers($filter: Filter) {
    userCounts(filter: $filter) {
      count
      partnerId
      thisMonth
    }
  }
`;

export const GET_USER = gql`
  query GetUserQuery($id: ID!) {
    user(id: $id) {
      addressMailing
      createdAt
      dob
      gender
      email
      altEmail
      employeeType
      employer
      id
      altId
      nameFirst
      nameLast
      partnerId
      Partner {
        partnerUrl
        name
      }
      phoneHome
      phoneMobile
      altPhoneMobile
      oldSmsEnabled
      oldMarketingEnabled
      marketingEmail
      marketingEnabled
      marketingEnabledOn
      marketingEnabledSource
      smsNumber
      smsEnabled
      smsEnabledOn
      smsEnabledSource
      firstTimeUse
    }
  }
`;

export const GET_USERS = gql`
  query GetUsersQuery($pagination: Pagination, $filter: Filter) {
    users(pagination: $pagination, filter: $filter) {
      pageInfo {
        currentPage
        totalPages
      }
      rows {
        createdAt
        email
        employeeType
        employer
        id
        nameFirst
        nameLast
        Partner {
          name
          partnerUrl
        }
      }
    }
  }
`;

export const SEARCH_USERS = gql`
  query SearchUsersQuery($search: String) {
    searchUsers(search: $search) {
      createdAt
      email
      id
      altId
      nameFirst
      nameLast
      partnerId
      Partner {
        name
      }
    }
  }
`;
