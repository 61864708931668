import { ApexOptions } from "apexcharts";
import React from "react";
import Chart from "react-apexcharts";

import { Box } from "./Box";
import { NoData } from "./NoData";

interface PieChartProps {
  isDonut?: boolean;
  labels: string[];
  series: number[];
}

export const PieChart = ({ isDonut, labels, series }: PieChartProps) => {
  if (!series.length) {
    return <NoData />;
  }

  const colors = isDonut
    ? [
        "#0881a3",
        "#18bfef",
        "#fb9f38",
        "#ef4f25",
        "#4BE584",
        "#4F50CC",
        "#F7CE46",
      ]
    : [
        "#18bfef",
        "#fb9f38",
        "#ef4f25",
        "#0881a3",
        "#4BE584",
        "#4F50CC",
        "#F7CE46",
      ];

  const options: ApexOptions = {
    colors,
    dataLabels: {
      enabled: false,
    },
    grid: {
      padding: {
        left: 0,
        right: 0,
      },
    },
    labels,
    legend: {
      fontFamily: "Gotham Rounded",
      fontSize: "14px",
      fontWeight: 350,
      position: "bottom",
      labels: {
        colors: "#615E83",
      },
      markers: {
        offsetY: -1,
        offsetX: -1,
      },
      itemMargin: {
        horizontal: series.length > 4 ? 8 : 16,
      },
    },
    plotOptions: {
      pie: {
        donut: {
          size: "85%",
        },
      },
    },
    yaxis: {
      labels: {
        formatter: (value) => value.toFixed(0) + "%",
      },
    },
    responsive: [
      {
        breakpoint: 300,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };

  return (
    <Box css={{ padding: "10px 0 20px 0" }}>
      <Chart
        options={options}
        series={series}
        type={isDonut ? "donut" : "pie"}
        height={350}
      />
    </Box>
  );
};
