import { useQuery } from "@apollo/client";
import { Filter } from "@soovu/shared/types";
import React from "react";

import { ENROLLMENTS_BY_MONTH } from "../queries/enrollment.queries";
import { EnrollmentCountsResponse } from "../types/enrollment.types";

import { BarChart } from "./BarChart";
import { Loading } from "./Loading";
import { NoData } from "./NoData";

type EnrollmentChartProps = {
  filter?: Filter;
};

export const EnrollmentChart = ({ filter }: EnrollmentChartProps) => {
  const labels: string[] = [];
  const series: number[] = [];

  const { data, loading } = useQuery<{
    enrollmentCounts: EnrollmentCountsResponse[];
  }>(ENROLLMENTS_BY_MONTH, { variables: { filter } });

  if (loading) {
    return <Loading />;
  }

  if (!data) {
    return <NoData />;
  }

  const { enrollmentCounts } = data;

  enrollmentCounts.forEach((obj) => {
    labels.push(obj.month);
    series.push(obj.count);
  });

  return (
    <BarChart
      series={[{ name: "Users", data: series }]}
      labels={labels}
      isColumn={true}
    />
  );
};
