import { useQuery } from "@apollo/client";
import { DIRECT_TO_CONSUMER_ID } from "@soovu/shared/constants";
import { Filter } from "@soovu/shared/types";
import { createColumnHelper, PaginationState } from "@tanstack/react-table";
import React, { useState } from "react";
import { Link } from "react-router-dom";

import { month_day_year } from "../lib/date_format";
import { getPartnerPath } from "../lib/util";
import { GET_USERS } from "../queries/user.queries";
import { GQLResponse } from "../types/common.types";
import { User } from "../types/user.types";

import { Box } from "./Box";
import Table from "./Table";

const columnHelper = createColumnHelper<User>();

const allColumns = {
  name: columnHelper.accessor(
    (row) => {
      if (
        (row.nameFirst === "" || row.nameFirst === null) &&
        (row.nameLast === "" || row.nameLast === null)
      ) {
        return row.email;
      }
      return (row.nameFirst ?? "") + " " + (row.nameLast ?? "");
    },
    {
      cell: (info) => {
        const prefix =
          info.row.original.Partner?.partnerUrl === DIRECT_TO_CONSUMER_ID
            ? "dtc"
            : "corporate";
        return (
          <Link to={`/${prefix}/user/${info.row.original.id}`}>
            {" "}
            <Box
              as={"span"}
              css={{ color: "#EF4F25", fontWeight: 350, whiteSpace: "nowrap" }}
            >
              {info.getValue()}
            </Box>
          </Link>
        );
      },
      header: "Individual",
    },
  ),
  email: columnHelper.accessor("email", {
    header: () => "Email",
  }),
  partner: columnHelper.accessor("Partner.name", {
    cell: (info) => (
      <Link to={getPartnerPath(info.row.original.Partner?.partnerUrl)}>
        <Box css={{ color: "#EF4F25", fontWeight: 350 }}>{info.getValue()}</Box>
      </Link>
    ),
    header: () => "Company",
  }),
  created: columnHelper.accessor("createdAt", {
    cell: (info) => {
      const value = info.getValue();
      if (!value) {
        return "null";
      }

      return month_day_year(value);
    },
    header: "Date Enrolled",
  }),
  employeeType: columnHelper.accessor("employeeType", {
    cell: (info) => info.getValue(),
    header: "Type",
  }),
  employer: columnHelper.accessor("employer", {
    cell: (info) => info.getValue(),
    header: "Type",
  }),
  id: columnHelper.accessor("id", {
    cell: (info) => {
      const id = parseInt(info.getValue(), 10);
      return id ? id + 1000 : null;
    },
    header: "UID",
  }),
};

const corpColumns = [
  allColumns.name,
  allColumns.email,
  allColumns.partner,
  allColumns.employeeType,
  allColumns.employer,
  allColumns.id,
  allColumns.created,
];

const d2cColumns = [
  allColumns.name,
  allColumns.email,
  allColumns.id,
  allColumns.created,
];

interface EnrollmentsTableProps {
  filter?: Filter;
}

export const UserTable = ({ filter }: EnrollmentsTableProps) => {
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const pagination = React.useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize],
  );

  const { data, loading } = useQuery<{ users: GQLResponse<User> }>(GET_USERS, {
    variables: {
      pagination: {
        page: pageIndex + 1,
        pageSize,
      },
      filter,
    },
  });

  if (loading || !data) {
    return <Box>...loading</Box>;
  }

  return (
    <Table
      columns={filter?.includeDirect === false ? corpColumns : d2cColumns}
      data={data.users}
      onPaginationChange={setPagination}
      pagination={pagination}
    />
  );
};
