import { ApexOptions } from "apexcharts";
import React from "react";
import Chart from "react-apexcharts";

import { Box } from "./Box";
import { NoData } from "./NoData";

type BarChartProps = {
  labels: string[];
  series: { name: string; data: number[] }[];
  isColumn?: boolean;
  isPercent?: boolean;
};

export const BarChart = ({
  labels,
  series,
  isColumn,
  isPercent,
}: BarChartProps) => {
  const colors = [
    "#18C0F0",
    "#FB9F38",
    "#EF4F25",
    "#0881A3",
    "#4BE584",
    "#4F50CC",
    "#F7CE46",
  ];

  const options: ApexOptions = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    colors,
    dataLabels: {
      enabled: true,
    },
    grid: {
      strokeDashArray: 6,
    },
    labels,
    legend: {
      show: false,
    },
    plotOptions: {
      bar: {
        borderRadius: 6,
        dataLabels: {
          position: "top",
        },
        distributed: true,
        horizontal: !isColumn,
        columnWidth: "50%",
      },
    },
    responsive: [
      {
        breakpoint: 300,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
    xaxis: {
      axisBorder: {
        offsetY: -1,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        formatter: (value) => value.toFixed(0),
      },
    },
  };

  if (isPercent) {
    options.yaxis = {
      labels: {
        formatter: (value) => value.toFixed(0) + "%",
      },
      max: 100,
    };

    options.dataLabels = {
      enabled: true,
      formatter: (value) => value + "%",
    };
  }

  if (!series[0]?.data?.length) {
    return <NoData />;
  }

  return (
    <Box css={{ padding: "10px 16px 0 8px" }}>
      <Chart options={options} series={series} type="bar" height={325} />
    </Box>
  );
};
