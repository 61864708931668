import { gql } from "@apollo/client";

export const GET_MONTHLY_USAGE_REPORTS = gql`
  query GetMonthlyUsageReports($pagination: Pagination, $filter: Filter) {
    monthlyUsageReports(pagination: $pagination, filter: $filter) {
      pageInfo {
        totalPages
      }
      rows {
        monthYear
        totalUsers
        totalSessions
      }
    }
  }
`;

export const GET_USAGE_COUNTS = gql`
  query GetUsageCounts($filter: Filter) {
    usageCounts(filter: $filter) {
      partnerId
      duration {
        min10
        min20
        min30
      }
      gain {
        max
        high
        med
        low
      }
      location {
        head
        neck
        shoulder
        pec
        biceps
        triceps
        arm
        hand
        chest
        abdomen
        trapezius
        back
        groin
        glutes
        hamstring
        calf
        leg
        knee
        shin
        footAnkle
      }
      preRating {
        unspecified
        skipped
        noRelief
        littleRelief
        better
        highRelief
        maxRelief
      }
      rating {
        unspecified
        skipped
        noRelief
        littleRelief
        better
        highRelief
        maxRelief
      }
      improvement {
        totalImproved
        totalSame
        totalNotImproved
        totalUnspecified
      }
      waveform {
        deep
        soothe
        breathe
      }
    }
  }
`;

export const GET_ACTIVE_USAGE = gql`
  query GetActiveUsage($pagination: Pagination, $filter: Filter) {
    activeUsage(pagination: $pagination, filter: $filter) {
      pageInfo {
        totalPages
      }
      rows {
        userId
        nameFirst
        nameLast
        email
        partnerId
        partnerName
        partnerUrl
        startDate
        totalSeconds
        totalSessions
      }
    }
  }
`;

export const ALL_GET_USAGE = gql`
  query GetUsage($pagination: Pagination, $filter: Filter) {
    usage(pagination: $pagination, filter: $filter) {
      pageInfo {
        totalPages
      }
      rows {
        id
        altId
        nameFirst
        nameLast
        partnerId
        partnerName
        BodyPartLookup {
          label
        }
        gain
        startTime
        actualSeconds
        Treatment {
          preRating
          postRating
          rating
          requestedSeconds
          serialNum
          podPlacement
          waveform
        }
      }
    }
  }
`;

export const GET_USAGE = gql`
  query GetUsage($pagination: Pagination, $filter: Filter) {
    usage(pagination: $pagination, filter: $filter) {
      pageInfo {
        totalPages
      }
      rows {
        BodyPartLookup {
          label
        }
        gain
        startTime
        actualSeconds
        Treatment {
          preRating
          postRating
          rating
          requestedSeconds
          serialNum
          podPlacement
          waveform
        }
      }
    }
  }
`;
