import { useQuery } from "@apollo/client";
import { Filter } from "@soovu/shared/types";
import React from "react";

import { AGE_COUNTS } from "../queries/age.queries";
import { AgeCountsResponse } from "../types/age.types";

import { Loading } from "./Loading";
import { NoData } from "./NoData";
import { PieChart } from "./PieChart";

type AgesChartProps = {
  filter?: Filter;
};

export const AgesChart = ({ filter }: AgesChartProps) => {
  const labels = [];
  const series = [];

  const { data, loading } = useQuery<{
    ageCounts: AgeCountsResponse;
  }>(AGE_COUNTS, { variables: { filter } });

  if (loading) {
    return <Loading />;
  }

  if (!data) {
    return <NoData />;
  }

  const { ageCounts } = data;
  const { age18To24, age25To39, age40To54, age55Plus, total } = ageCounts;

  if (age18To24) {
    labels.push("18-24");
    series.push(Math.round((age18To24 / total) * 100));
  }

  if (age25To39) {
    labels.push("25-39");
    series.push(Math.round((age25To39 / total) * 100));
  }

  if (age40To54) {
    labels.push("40-54");
    series.push(Math.round((age40To54 / total) * 100));
  }

  if (age55Plus) {
    labels.push("55+");
    series.push(Math.round((age55Plus / total) * 100));
  }

  return <PieChart series={series} labels={labels} />;
};
